<template>
  <!-- bloque banner -->
  <div class="bloque-banner">
    <v-container>
      <div class="align-text">
        <h1 class="text-h3 text-md-h2 font-weight-bold mb-8">
          Lorem ipsum dolor sit amet, consectetur
        </h1>
        <h5 class="subtitle-banner text-h6 text-md-h5 mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mi nibh,
          maximus ut
        </h5>
        <v-btn
          to="/registro"
          class="white--text py-7 pr-9 pl-9 btn-orange"
          rounded
          >Registrarme</v-btn
        >
      </div>
    </v-container>
    <div class="content-image">
      <div class="img-banner">
        <v-img
          alt="Vuetify Logo"
          class="shrink"
          contain
          :src="require('@/assets/banner-home.png')"
          width="100%"
        />
      </div>
    </div>
    <div class="container-scroll">
      <div class="icon-letras">
        <v-img
          alt="icon scroll"
          class="shrink img-rotate"
          contain
          :src="require('@/assets/icon-scroll.svg')"
          width="100%"
        />
      </div>
      <div class="icon-center">
        <v-img
          alt="icon scroll"
          class="shrink"
          contain
          :src="require('@/assets/arrow-down.svg')"
          width="100%"
        />
      </div>
    </div>
  </div>
  <!-- bloque banner -->
</template>
<script>
import { gsap } from "gsap";
export default {
  mounted() {
    gsap.to(".img-rotate", {
      duration: 12,
      rotation: "360",
      transformOrigin: "50% 50%",
      repeat: -1,
    });
  },
  created() {},
};
</script>
<style lang="scss">
.bloque-banner {
  width: 100%;
  padding-top: 50px;
  position: relative;
  .container-scroll {
    position: absolute;
    bottom: 10%;
    left: 12%;
    display: none;
    .icon-letras {
      width: 100px;
    }
    .icon-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25px;
    }
    /* Medium and up */
    @media screen and (min-width: 40em) {
      display: block;
    }
  }
  .align-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
    .subtitle-banner {
      width: 100%;
      /* Medium and up */
      @media screen and (min-width: 40em) {
        width: 70%;
      }
    }
    /* Medium and up */
    @media screen and (min-width: 40em) {
      width: 70%;
      margin: 0 auto;
    }
  }
  .content-image {
    width: 100%;
    background-image: linear-gradient(to top, rgba(#ffbf94, 0.15), #fff);
    padding-bottom: 60px;
    .img-banner {
      width: 80%;
      margin: 0 auto;
      /* Large and up */
      @media screen and (min-width: 64em) {
        width: 35%;
      }
    }
    /* Large and up */
    @media screen and (min-width: 64em) {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 0;
    }
  }
  /* Large and up */
  @media screen and (min-width: 64em) {
    min-height: calc(100vh - 80px);
    padding-top: 80px;
    padding-bottom: 200px;
  }
}
</style>