import { firebase, auth, db, storage, marcaTiempo, functions } from "@/firebase";
export default {
  namespaced: true,
  state: {
    listEncuestas: [],
    editEncuesta: {},
    respuestaEncuesta: {}
  },
  mutations: {
    setEncuestas(state, payload) {
      state.listEncuestas = payload
    },
    setEncuesta(state, payload) {
      state.editEncuesta = payload
    },
    deleteEncuesta(state, payload) {
      state.listEncuestas = state.listEncuestas.filter(item => item.id !== payload)
    },
    setRespuestaEncuesta(state, payload) {
      state.respuestaEncuesta = payload;
    }
  },
  actions: {
    async obtieneEncuestas({ commit, state, rootState }, paramEncuesta) {
      try {
        const queryEncuestas = await db.collection("encuestas").where('uid', "==", rootState.usuario.uid).limit(1000).get()
        const list = []
        queryEncuestas.forEach(element => {
          let encuestaObject = element.data();
          encuestaObject.id = element.id;
          list.push(encuestaObject)
        });
        commit('setEncuestas', list)
        return {
          res: true,
          error: ""
        }
      } catch (error) {
        return {
          res: false,
          error: "Error al consultar las encuestas"
        }
      }
    },
    async obtieneEditEncuesta({ commit, state, rootState }, idParamEncuesta) {
      try {
        const queryEncuesta = await db.collection("encuestas").doc(idParamEncuesta).get()
        let dataEncuesta = queryEncuesta.data()
        dataEncuesta.id = queryEncuesta.id;
        commit('setEncuesta', dataEncuesta);
        return {
          res: true,
          error: ""
        }
      } catch (error) {
        return {
          res: false,
          error: "Error al consultar los datos de la encuesta"
        }
      }
    },
    async obtieneResponderEncuesta({ commit, state, rootState }, idParamEncuesta) {
      try {
        const queryEncuesta = await db.collection("encuestas").doc(idParamEncuesta).get()
        let dataEncuesta = queryEncuesta.data()
        dataEncuesta.id = queryEncuesta.id;
        if (dataEncuesta.preguntas.length > 0) {
          dataEncuesta.preguntas.forEach(element => {
            element.respuesta = ""
          });
        }
        commit('setRespuestaEncuesta', dataEncuesta);
        return {
          res: true,
          error: ""
        }
      } catch (error) {
        return {
          res: false,
          error: "Error al consultar los datos de la encuesta"
        }
      }
    },
    async creaEncuestaData({ commit, state, rootState }, paramEncuesta) {
      try {
        const objEncuesta = {
          title: paramEncuesta.nombre,
          descripcion: paramEncuesta.descripcion,
          preguntas: paramEncuesta.preguntas,
          slug: paramEncuesta.slug,
          estatus: true,
          uid: rootState.usuario.uid,
        };
        await db.collection("encuestas").add(objEncuesta)
        return {
          res: true
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async actualizarEncuestaData({ commit, state }, paramEncuesta) {
      try {
        let updateEncuesta = {
          title: paramEncuesta.title,
          descripcion: paramEncuesta.descripcion,
          preguntas: paramEncuesta.preguntas
        };
        await db.collection("encuestas").doc(paramEncuesta.id).update(updateEncuesta);
        return {
          res: true,
          error: ""
        }
      } catch (error) {
        return {
          res: false,
          error: "Hubo un error al actualizar la información"
        }
      }
    },
    async eliminarEncuesta({ commit }, idEncuesta) {
      try {
        await db.collection("encuestas").doc(idEncuesta.id).delete();
        commit("deleteEncuesta", idEncuesta);
        return {
          res: true,
          error: ""
        }
      } catch (error) {
        return {
          res: false,
          error: "Hubo un error al eliminar la encuesta"
        }
      }
    },
    async guardaRespuestasEncuesta({ commit }, paramEncuesta) {
      try {
        let insertRespuestas = {
          correo: paramEncuesta.correo,
          respuestas: paramEncuesta.respuestas
        };
        await db.collection("encuestas").doc(paramEncuesta.id).collection("respuestas").add(insertRespuestas);
        return {
          res: true,
          error: ""
        }
      } catch (error) {
        return {
          res: false,
          error: "Hubo un error al guardar tus respuestas. Vuelve a intentarlo más tarde."
        }
      }
    }
  }
}