import { firebase, auth, db, storage, marcaTiempo, functions } from "@/firebase";
export default {
    namespaced: true,
    state: {
        listRestaurantes: [],
        informacionRestaurante: null,
        categorias: [],
        subcategorias: [],
        platillos: [],
        menuView: [],
        listCategoriaChange: [],
    },
    mutations: {
        setEstatusInfoResta(state, payload) {
            state.informacionRestaurante.estatus = payload;
        },
        setInformacionRestaurante(state, payload) {
            state.informacionRestaurante = payload;
        },
        setRestaurantes(state, payload) {
            state.listRestaurantes = payload
        },
        deleteRestaurantes(state, payload) {
            state.listRestaurantes = state.listRestaurantes.filter(item => item.id !== payload.id)
        },
        setActualizarInfoRest(state, payload) {
            if (state !== null) {
                state.informacionRestaurante.nombre = payload.nombre;
                state.informacionRestaurante.direccion = payload.direccion;
                state.informacionRestaurante.slug = payload.slug;
                state.informacionRestaurante.foto = payload.foto;
                state.informacionRestaurante.facebook = payload.facebook;
                state.informacionRestaurante.instagram = payload.instagram;
            }
        },
        setActualizaConfigRest(state, payload) {
            if (state !== null) {
                state.informacionRestaurante.imgPrincipal = payload.imgPrincipal;
                state.informacionRestaurante.tipoFondo = payload.tipoFondo;
                state.informacionRestaurante.colorFondo = payload.colorFondo;
                state.informacionRestaurante.fondoImg = payload.fondoImg;
                state.informacionRestaurante.colorBar = payload.colorBar;
                state.informacionRestaurante.colorBar = payload.colorBar;
            }
        },
        setCategorias(state, payload) {
            state.categorias = payload
        },
        addCategoria(state, payload) {
            state.categorias.push(payload)
        },
        actualizarItemCategoria(state, payload) {
            state.categorias = state.categorias.map(item => item.id === payload.id ? payload : item)
        },
        deleteCategorias(state, payload) {
            state.categorias = state.categorias.filter(item => item.id !== payload)
        },
        setCategoriaChange(state, payload) {
            if (payload.length > 0) {
                for (let index = 0; index < payload.length; index++) {
                    let existInList = state.listCategoriaChange.indexOf(payload[index])
                    if (existInList === -1)
                        state.listCategoriaChange.push(payload[index])
                    else {
                        state.listCategoriaChange = state.listCategoriaChange
                            .map(item => item.id === payload[index].id ? payload[index] : item)
                    }
                }
            }
        },
        clearCategoriasChange(state, payload) {
            state.listCategoriaChange = payload
        },
        addSubCategoria(state, payload) {
            state.subcategorias.push(payload)
        },
        setSubCategorias(state, payload) {
            state.subcategorias = payload
        },
        updateSubCategorias(state, payload) {
            state.subcategorias = state.subcategorias.map(item => item.id === payload.id ? payload : item)
        },
        deleteSubCategorias(state, payload) {
            state.subcategorias = state.subcategorias.filter(item => item.id !== payload)
        },
        setPlatillos(state, payload) {
            state.platillos = payload
        },
        addPlatillo(state, payload) {
            state.platillos.push(payload)
        },
        deletePlatillo(state, payload) {
            state.platillos = state.platillos.filter(item => item.id !== payload.id)
        },
        updatePlatillos(state, payload) {
            state.platillos = state.platillos.map(item => item.id === payload.id ? payload : item)
        },
        addPlatilloCategoria(state, payload) {
            const findCategoria = state.categorias.find(item => item.id === payload.idCategoria);
            findCategoria.platillos.push(payload);
            state.categorias = state.categorias.map(item => item.id === payload.idCategoria ? findCategoria : item)
        },
        setMenuView(state, payload) {
            state.menuView = payload;
        },
        resetDataMenuView(state, payload) {
            state.categorias = [];
            state.subcategorias = [];
            state.platillos = [];
            state.menuView = [];
            state.informacionRestaurante = null;
        }
    },
    actions: {
        async obtieneRestaurantes({ commit, state, rootState }) {
            try {
                if (rootState.usuario == null)
                    return
                const query = await db.collection('restaurantes')
                    .where('uid', "==", rootState.usuario.uid)
                    .where('estatus', '==', true)
                    .get()
                const list = []
                query.forEach(element => {
                    let restaurantObject = element.data();
                    restaurantObject.id = element.id;
                    list.push(restaurantObject)
                });
                commit('setRestaurantes', list)
                return {
                    res: true,
                    error: ""
                }
            } catch (error) {
                return {
                    res: false,
                    error: "Hubo un error al obtener los restaurantes"
                }
            }
        },
        async activarRestaurante({ commit }, restauranteParam) {
            try {
                let restaurantUpdate = {
                    estatus: true
                }
                await db.collection('restaurantes').doc(restauranteParam.id).update(restaurantUpdate);
                commit("setEstatusInfoResta", true);
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                return {
                    res: false,
                    error: ""
                }
            }
        },
        async desactivaRestaurante({ commit }, paramRestaurante) {
            try {
                //valido que tenga categorias
                const exitsCat = await db.collection('restaurantes').doc(paramRestaurante.id).collection('categorias').get()
                if (exitsCat.size > 0) {
                    await db.collection('restaurantes').doc(paramRestaurante.id).update({
                        estatus: paramRestaurante.estatus
                    })
                } else {
                    await db.collection('restaurantes').doc(paramRestaurante.id).delete();
                }
                commit('deleteRestaurantes', paramRestaurante)
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                return {
                    res: false,
                    error: error
                }
            }
        },
        async creaRestaurante({ commit, state, rootState }, restauranteParam) {
            try {
                //valido que no exista un slug
                const existSlug = await db.collection('restaurantes').where('slug', '==', restauranteParam.slug).get()
                if (!existSlug.size > 0) {
                    let restaurantInsert = {
                        uid: rootState.usuario.uid,
                        nombre: restauranteParam.nombre,
                        slug: restauranteParam.slug,
                        direccion: restauranteParam.direccion,
                        estatus: true,
                        fecha: marcaTiempo(),
                        foto: '',
                        fondoImg: '',
                        imgPrincipal: '',
                        colorTextoPrincipal: "#383838FF",
                        colorBar: "#FFFFFFFF",
                        tipoFondo: "color",
                        colorFondo: "#E8EDEBFF",
                        urlWebsite: "",
                        facebook: restauranteParam.facebook,
                        instagram: restauranteParam.instagram
                    }
                    const createRestaurante = await db.collection('restaurantes').add(restaurantInsert)
                    restaurantInsert.id = createRestaurante.id
                    //valido que haya imagen para almacenarla
                    if (restauranteParam.foto && restauranteParam.foto.size > 0) {
                        const refImagen = storage.ref().child(rootState.usuario.email)
                            .child('logosRestaurante').child(restaurantInsert.id)
                            .child('logo')
                        const res = await refImagen.put(restauranteParam.foto)
                        const urlDescarga = await refImagen.getDownloadURL()
                        restaurantInsert.foto = urlDescarga
                        //actualiza foto del restaurante
                        await db.collection('restaurantes').doc(restaurantInsert.id).update({
                            foto: urlDescarga
                        })
                    }
                    commit('setInformacionRestaurante', restaurantInsert)
                    return {
                        res: true,
                        idRest: createRestaurante.id,
                        error: ''
                    }
                } else {
                    return {
                        res: false,
                        error: 'El slug ya se encuentra en uso'
                    }
                }
            } catch (error) {
                return {
                    res: false,
                    error: "Se genero un error, verifica tus datos."
                }
            }
        },
        async obtieneInformacionRestaurante({ commit }, idParam) {
            try {
                const query = await db.collection('restaurantes').doc(idParam).get()
                let dataRestaurant = query.data()
                dataRestaurant.id = query.id;
                commit('setInformacionRestaurante', dataRestaurant);
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                return {
                    res: false,
                    error: "Se genero un error al intentar consultar su información."
                }
            }
        },
        async actualizarInfoRestaurante({ commit, state, rootState }, restauranteParam) {
            try {
                let objReturn = {
                    res: true,
                    foto: restauranteParam.foto,
                    error: ''
                }
                let restaurantUpdate = {
                    nombre: restauranteParam.nombre,
                    slug: (restauranteParam.slug != "") ? restauranteParam.slug : restauranteParam.slugActual,
                    direccion: restauranteParam.direccion,
                    foto: restauranteParam.foto,
                    facebook: restauranteParam.facebook,
                    instagram: restauranteParam.instagram
                }
                //valido que haya imagen para almacenarla
                if (restauranteParam.imagenFile && restauranteParam.imagenFile.size > 0) {
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('logosRestaurante').child(restauranteParam.id)
                        .child('logo')
                    const res = await refImagen.put(restauranteParam.imagenFile)
                    //se obtiene la url donde se almaceno en firestore
                    const urlDescarga = await refImagen.getDownloadURL()
                    restaurantUpdate.foto = urlDescarga
                    objReturn.foto = urlDescarga
                }
                await db.collection('restaurantes').doc(restauranteParam.id).update(restaurantUpdate);
                commit("setActualizarInfoRest", restaurantUpdate);
                return objReturn;
            } catch (error) {
                return {
                    res: false,
                    error: "Se genero un error, verifica tus datos."
                }
            }
        },
        async actualizaConfigRestaurante({ commit, state, rootState }, paramInfo) {
            try {
                let objReturn = {
                    res: true,
                    imgPrincipal: paramInfo.imgPrincipal,
                    fondoImg: paramInfo.fondoImg,
                    error: ''
                }
                let restaurantUpdate = {
                    imgPrincipal: paramInfo.imgPrincipal,
                    tipoFondo: paramInfo.tipoFondo,
                    colorFondo: paramInfo.colorFondo,
                    fondoImg: paramInfo.fondoImg,
                    colorBar: paramInfo.colorBar,
                    colorTextoPrincipal: paramInfo.colorTextoPrincipal
                }
                // valido quey hay una imagen principal
                if (paramInfo.imagenPricipalFile && paramInfo.imagenPricipalFile.size > 0) {
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('imgBanners').child(paramInfo.id)
                        .child('banner')
                    const res = await refImagen.put(paramInfo.imagenPricipalFile)
                    const urlDescarga = await refImagen.getDownloadURL()
                    restaurantUpdate.imgPrincipal = urlDescarga
                    objReturn.imgPrincipal = urlDescarga
                }
                //valido que haya imagen para almacenarla
                if (paramInfo.imagenFondoFile && paramInfo.imagenFondoFile.size > 0) {
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('fondosRestaurante').child(paramInfo.id)
                        .child('fondo')
                    const res = await refImagen.put(paramInfo.imagenFondoFile)
                    //se obtiene la url donde se almaceno en firestore
                    const urlDescarga = await refImagen.getDownloadURL()
                    restaurantUpdate.fondoImg = urlDescarga
                    objReturn.fondoImg = urlDescarga
                }
                await db.collection('restaurantes').doc(paramInfo.idRestaurante).update(restaurantUpdate);
                return objReturn;
            } catch (error) {
                console.log(error)
                return {
                    res: false,
                    error: 'Se género un error, al intentar guardar.'
                }
            }
        },
        async obtieneCategoriasRestaurante({ commit }, idParam) {
            try {
                const query = await db.collection('restaurantes')
                    .doc(idParam).collection('categorias')
                    .limit(600)
                    .orderBy("order", "asc")
                    .get()
                const list = []
                query.forEach(element => {
                    let categoria = element.data()
                    categoria.id = element.id
                    categoria.comentarios = (categoria.comentarios) ? categoria.comentarios : "";
                    categoria.platillos = []
                    list.push(categoria)
                });
                commit('setCategorias', list)
                return {
                    res: true,
                }
            } catch (error) {
                return {
                    res: false,
                    error: 'Se género un error, al intentar consultar las categorias.'
                }
            }
        },
        async creaCategoria({ commit, state, rootState }, paramCategoria) {
            try {
                let categoria = {
                    urlImage: '',
                    title: paramCategoria.nombre,
                    color: paramCategoria.color,
                    colorCaja: paramCategoria.colorCaja,
                    estatus: true,
                    order: paramCategoria.order,
                    comentarios: paramCategoria.comentarios
                }
                const createCategory = await db.collection('restaurantes')
                    .doc(paramCategoria.idRestaurante)
                    .collection("categorias").add(categoria)
                categoria.id = createCategory.id
                if (categoria.id && paramCategoria.subcategorias.length > 0) {
                    let orderSub = 0;
                    paramCategoria.subcategorias.forEach(async element => {
                        let subcategoriaCreate = {
                            title: element.title,
                            idCategoria: categoria.id,
                            estatus: true,
                            order: orderSub
                        }
                        let queryCreateSub = await db.collection('restaurantes')
                            .doc(paramCategoria.idRestaurante)
                            .collection("subcategorias").add(subcategoriaCreate)
                        subcategoriaCreate.id = queryCreateSub.id;
                        commit('addSubCategoria', subcategoriaCreate)
                        orderSub++;
                    });
                }
                //valido que haya imagen para almacenarla
                if (paramCategoria.imagen && paramCategoria.imagen.size > 0) {
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('categorias').child(categoria.id)

                    const res = await refImagen.put(paramCategoria.imagen)
                    const urlDescarga = await refImagen.getDownloadURL()
                    categoria.urlImage = urlDescarga
                    //actualiza foto del restaurante
                    await db.collection('restaurantes')
                        .doc(paramCategoria.idRestaurante)
                        .collection("categorias").doc(categoria.id).update({
                            urlImage: urlDescarga
                        })
                }
                commit('addCategoria', categoria)
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                console.log(error);
                return {
                    res: false,
                    error: "Se genero un error al crear la categoría"
                }
            }
        },
        async actualizaCategoria({ commit, state, rootState }, paramTwoCategoria) {
            try {
                let objReturn = {
                    res: true,
                    error: ''
                }
                let categoriaData = {
                    title: paramTwoCategoria.categoriaItemUp.title,
                    color: paramTwoCategoria.categoriaItemUp.color,
                    colorCaja: paramTwoCategoria.categoriaItemUp.colorCaja
                }
                //valido que haya imagen para almacenarla
                if (paramTwoCategoria.imagen && paramTwoCategoria.imagen.size > 0) {
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('categorias').child(paramTwoCategoria.categoriaItemUp.id)
                    const res = await refImagen.put(paramTwoCategoria.imagen)
                    const urlDescarga = await refImagen.getDownloadURL()
                    categoriaData.urlImage = urlDescarga
                    objReturn.urlImagen = urlDescarga
                }
                await db.collection('restaurantes')
                    .doc(paramTwoCategoria.idRestaurante)
                    .collection('categorias').doc(paramTwoCategoria.categoriaItemUp.id).update(categoriaData);
                commit("actualizarItemCategoria", paramTwoCategoria.categoriaItemUp)
                return objReturn;
            } catch (error) {
                console.log(error)
                return {
                    res: false,
                    error: "Se genero un error al actualizar la categoría"
                }
            }
        },
        async eliminaCategoria({ commit, state, rootState }, paramCategoria) {
            try {
                const refRestaurantes = db.collection('restaurantes')
                    .doc(paramCategoria.idRestaurante)
                const platillos = await refRestaurantes.collection('platillos')
                    .where('idCategoria', '==', paramCategoria.id).get()
                //elimino la imagen que se subio de la categoria
                if (paramCategoria.urlImage) {
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('categorias').child(paramCategoria.id)
                    refImagen.delete()
                }
                //valido si la categoria tiene platillos para remover referencias en los platillos
                if (platillos.size > 0) {

                    platillos.forEach(doc => {
                        doc.ref
                            .delete()
                    })
                }
                const subcategorias = await refRestaurantes.collection('subcategorias')
                    .where('idCategoria', '==', paramCategoria.id).get()
                //valido si la categoria tiene platillos para remover referencias en los platillos
                if (subcategorias.size > 0) {
                    subcategorias.forEach(doc => {
                        doc.ref
                            .delete();
                    })
                }
                await refRestaurantes.collection('categorias')
                    .doc(paramCategoria.id).delete()
                //actualizo datos para platillos
                commit('deleteCategorias', paramCategoria.id)
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                return {
                    res: false,
                    error: error
                }
            }
        },
        async obtieneSubCategoriasRestaurante({ commit }, idRestaurante) {
            try {
                const query = await db.collection('restaurantes')
                    .doc(idRestaurante)
                    .collection('subcategorias')
                    .limit(600)
                    .get()
                const list = []
                query.forEach(element => {
                    let subcategoria = element.data()
                    subcategoria.id = element.id
                    list.push(subcategoria)
                });
                commit('setSubCategorias', list)
                return {
                    res: true
                }
            } catch (error) {
                return {
                    res: false,
                    error: error
                }
            }
        },
        async obtienePlatillos({ commit }, idRestaurante) {
            try {
                const query = await db.collection('restaurantes')
                    .doc(idRestaurante)
                    .collection('platillos')
                    .limit(600)
                    .get()
                const list = []
                query.forEach(element => {
                    let platillo = element.data()
                    platillo.precioEspecial = (platillo.precioEspecial) ? platillo.precioEspecial : ""
                    platillo.id = element.id
                    list.push(platillo)
                });
                commit('setPlatillos', list)
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                return {
                    res: false,
                    error: error
                }
            }
        },
        async creaPlatillo({ commit, state, rootState }, paramPlatillo) {
            try {
                let platillo = {
                    urlImage: '',
                    title: paramPlatillo.platillo.nombre,
                    descripcion: (paramPlatillo.platillo.descripcion) ? paramPlatillo.platillo.descripcion : "",
                    precio: (paramPlatillo.platillo.precio) ? paramPlatillo.platillo.precio : "",
                    precioUnitario: (paramPlatillo.platillo.precioUnitario) ? paramPlatillo.platillo.precioUnitario : "",
                    labelUnitario: paramPlatillo.platillo.labelUnitario,
                    estatus: true,
                    idCategoria: (paramPlatillo.platillo.idCategoria) ? paramPlatillo.platillo.idCategoria : "",
                    idSubcategoria: (paramPlatillo.platillo.idSubcategoria) ? paramPlatillo.platillo.idSubcategoria : "",
                    precioEspecial: paramPlatillo.platillo.precioEspecial
                }
                const result = await db.collection('restaurantes')
                    .doc(paramPlatillo.idRestaurante)
                    .collection('platillos')
                    .add(platillo)
                //agrego al id que se genera automaticamente
                platillo.id = result.id
                // valido que haya imagen para almacenarla
                if (paramPlatillo.imagen && paramPlatillo.imagen.size > 0) {
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('platillos').child(platillo.id)
                    const res = await refImagen.put(paramPlatillo.imagen)
                    const urlDescarga = await refImagen.getDownloadURL()
                    platillo.urlImage = urlDescarga
                    //actualiza foto del restaurante
                    await db.collection('restaurantes')
                        .doc(paramPlatillo.idRestaurante)
                        .collection('platillos').doc(platillo.id).update({
                            urlImage: urlDescarga
                        })
                }
                commit('addPlatillo', platillo)
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                console.log(error);
                return {
                    res: false,
                    error: error
                }
            }
        },
        async actualizaPlatillo({ commit, state, rootState }, paramPlatillo) {
            try {

                let platilloUpdate = {
                    title: paramPlatillo.platillo.title,
                    descripcion: paramPlatillo.platillo.descripcion,
                    precio: paramPlatillo.platillo.precio,
                    precioUnitario: (paramPlatillo.platillo.precioUnitario == "") ? "" : paramPlatillo.platillo.precioUnitario,
                    labelUnitario: paramPlatillo.platillo.labelUnitario,
                    idCategoria: paramPlatillo.platillo.idCategoria,
                    idSubcategoria: paramPlatillo.platillo.idSubcategoria,
                    urlImage: paramPlatillo.platillo.urlImage,
                    precioEspecial: paramPlatillo.platillo.precioEspecial
                }
                //valido que haya imagen para remplazarla
                if (paramPlatillo.imagen && paramPlatillo.imagen.size > 0) {
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('platillos').child(paramPlatillo.platillo.id)

                    const res = await refImagen.put(paramPlatillo.imagen)
                    const urlDescarga = await refImagen.getDownloadURL()
                    platilloUpdate.urlImage = urlDescarga
                }
                await db.collection('restaurantes')
                    .doc(paramPlatillo.idRestaurante)
                    .collection('platillos')
                    .doc(paramPlatillo.platillo.id)
                    .update(platilloUpdate)
                commit('updatePlatillos', paramPlatillo.platillo)
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                console.log(error)
                return {
                    res: false,
                    error: error
                }
            }
        },
        async eliminaPlatillo({ commit, state, rootState }, paramPlatillo) {
            try {
                //valido que haya imagen para eliminarla
                if (paramPlatillo.urlImage) {
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('platillos').child(paramPlatillo.id)

                    refImagen.delete()
                }
                await db.collection('restaurantes')
                    .doc(paramPlatillo.idRestaurante)
                    .collection('platillos').doc(paramPlatillo.id).delete()
                commit('deletePlatillo', paramPlatillo)
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                return {
                    res: false,
                    error: error
                }
            }
        },
        getMenuView({ commit, state }) {
            const paramListData = {
                categorias: state.categorias,
                platillos: state.platillos,
                subcategorias: state.subcategorias
            }
            const menuList = [];
            if (paramListData.categorias.length > 0) {
                paramListData.categorias.forEach(catElement => {
                    let categoria = catElement
                    categoria.platillos = []
                    categoria.subcategorias = []
                    //valido que hay platillos
                    if (paramListData.platillos.length > 0) {
                        categoria.platillos = paramListData.platillos.filter(item => item.idCategoria == categoria.id && item.idSubcategoria == 0)
                    }
                    if (paramListData.subcategorias.length > 0) {
                        //recorro las subcategorias
                        paramListData.subcategorias.forEach(element => {
                            let subcategoria = element
                            //valido que la subcategoria le pertenesca a la categoria - padre
                            if (subcategoria.idCategoria == categoria.id) {
                                //valido que hay platillos
                                if (paramListData.platillos.length > 0) {
                                    subcategoria.platillos = paramListData.platillos.filter(item => item.idSubcategoria == subcategoria.id)
                                } else {
                                    subcategoria.platillos = []
                                }
                                categoria.subcategorias.push(subcategoria);
                            }
                        });
                        categoria.subcategorias = categoria.subcategorias.sort(function (a, b) { return a.order - b.order });
                    }
                    menuList.push(categoria)
                })
            }
            commit("setMenuView", menuList)
        },
        resetMenuView({ commit }) {
            commit("resetDataMenuView", "");
        },
        async creaSubCategoria({ commit, state }, paramCategoria) {
            try {
                let subcategoria = {
                    title: paramCategoria.title,
                    idCategoria: paramCategoria.idCategoria,
                    estatus: true,
                    order: 0
                }
                const createSubCategory = await db.collection('restaurantes')
                    .doc(paramCategoria.idRestaurante)
                    .collection("subcategorias").add(subcategoria)
                subcategoria.id = createSubCategory.id
                commit('addSubCategoria', subcategoria)
                return {
                    res: true,
                    idCreated: createSubCategory.id,
                    error: ''
                }
            } catch (error) {
                return {
                    res: false,
                    error: "Se genero un error al agregar la Sub categoría"
                }
            }
        },
        async actualizaSubCategoria({ commit }, paramSubCategoria) {
            try {
                let subcategoria = {
                    title: paramSubCategoria.dataSub.title,
                    order: paramSubCategoria.dataSub.order
                }
                await db.collection('restaurantes')
                    .doc(paramSubCategoria.idRestaurante)
                    .collection('subcategorias').doc(paramSubCategoria.dataSub.id).update(subcategoria);
                commit('updateSubCategorias', paramSubCategoria.dataSub);
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                return {
                    res: false,
                    error: error
                }
            }
        },
        async eliminaSubcategoria({ commit }, paramSubCategoria) {
            try {
                const refRestaurantes = db.collection('restaurantes')
                    .doc(paramSubCategoria.idRestaurante)
                const platillos = await refRestaurantes.collection('platillos')
                    .where('idSubcategoria', '==', paramSubCategoria.idSub).get()
                //valido que la subcategoria tenga platillos en caso de que si
                if (platillos.size > 0) {
                    //reseteo los platillos que tenga la subcategoria
                    platillos.forEach(async doc => {
                        let platilloUpdate = doc.data()
                        platilloUpdate.id = doc.id
                        platilloUpdate.idSubcategoria = ""
                        doc.ref
                            .update({
                                idSubcategoria: ""
                            })
                        commit('updatePlatillos', platilloUpdate)
                    })
                }
                // si no tiene platillos elimino la subcategoria para no generar basura en la bd
                await refRestaurantes.collection('subcategorias')
                    .doc(paramSubCategoria.idSub).delete()
                commit('deleteSubCategorias', paramSubCategoria.idSub)
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                return {
                    res: false,
                    error: error
                }
            }
        },
        async guardarOrderCategorias({ commit, state }, idRestaurante) {
            try {
                if(state.listCategoriaChange.length > 0){
                    state.listCategoriaChange.forEach(async element => {
                        await db.collection('restaurantes')
                            .doc(idRestaurante)
                            .collection('categorias').doc(element.id).update({
                                order: element.order
                            })
                    });
                    commit('clearCategoriasChange', [])
                }
                return {
                    res: true,
                    error: ""
                }
            } catch (error) {
                return {
                    res: false,
                    error: error
                }
            }
        },
    }
}