<template>
  <div class="container-landing">
    <BloqueBanner></BloqueBanner>
    <BloqueComoFunciona></BloqueComoFunciona>
    <BloqueCaracteristicas></BloqueCaracteristicas>
    <BloqueTestimonios></BloqueTestimonios>
    <BloquePlanes></BloquePlanes>
    <BloqueRegistrate></BloqueRegistrate>
    <Footer></Footer>
  </div>
</template>
<script>
import BloqueBanner from "@/components/landing/BloqueBanner";
import BloqueComoFunciona from "@/components/landing/BloqueComoFunciona";
import BloqueCaracteristicas from "@/components/landing/BloqueCaracteristicas";
import BloqueTestimonios from "@/components/landing/BloqueTestimonios";
import BloqueRegistrate from "@/components/landing/BloqueRegistrate";
import BloquePlanes from "@/components/landing/BloquePlanes";
import Footer from "@/components/Footer";
export default {
  name: "Home",
  components:{
    BloqueBanner,
    BloqueCaracteristicas,
    BloqueTestimonios,
    BloqueRegistrate,
    BloquePlanes,
    BloqueComoFunciona,
    Footer
  }
};
</script>
<style lang="scss">
.container-landing {
  width: 100%;
}
</style>
