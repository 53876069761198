import { firebase, auth, db, storage, marcaTiempo, functions } from "@/firebase";
import moment from "moment";
export default {
    namespaced: true,
    state: {
        listUsuarios: [],
        listMembresias: [],
        listSuscripciones: [],
        membresiaUsuario: null
    },
    mutations: {
        setUsuarios(state, payload) {
            state.listUsuarios = payload
        },
        setMembrecias(state, payload) {
            state.listMembresias = payload
        },
        setSuscripciones(state, payload) {
            state.listSuscripciones = payload
        },
        addSuscripciones(state, payload) {
            state.listSuscripciones.push(payload);
        },
        setMembrenciaUsuario(state, payload) {
            state.membresiaUsuario = payload;
        }
    },
    actions: {
        async obtieneUsuarios({ commit }) {
            try {
                const queryUsuarios = await db.collection('usuarios').where('role', '==', 'customer').get()
                const list = []
                queryUsuarios.forEach(async userElement => {
                    let objUsuario = {}
                    objUsuario = userElement.data()
                    objUsuario.id = userElement.id
                    list.push(objUsuario)
                })
                commit('setUsuarios', list)
            } catch (error) {
                console.log(error)
            }
        },
        async obtieneMembresias({ commit }) {
            try {
                const queryMembresias = await db.collection('membresias').where('estatus', '==', true).get()
                const list = []
                queryMembresias.forEach(async userElement => {
                    let objMembresia = {}
                    objMembresia = userElement.data()
                    objMembresia.id = userElement.id
                    list.push(objMembresia)
                })
                commit('setMembrecias', list)
            } catch (error) {
                console.log(error)
            }
        },
        async obtieneSuscripciones({ commit }) {
            try {
                const queryMembresias = await db.collection('suscripciones').limit(200).get()
                const list = []
                queryMembresias.forEach(async elementMembresia => {
                    let objMembresia = {}
                    objMembresia = elementMembresia.data();
                    objMembresia.id = elementMembresia.id;
                    const currentFecha = moment(new Date(), "MMMM Do YYYY");
                    const fin = moment(objMembresia.fechaFin, "MMMM Do YYYY");
                    objMembresia.vencido = (currentFecha.diff(fin, "days") > 0) ? true : false;
                    list.push(objMembresia);
                })
                commit('setSuscripciones', list)
            } catch (error) {
                console.log(error)
            }
        },
        async guardaMembresia({ commit }, paramMembresia) {
            try {
                //valido que no exista un slug
                const existSlug = await db.collection('suscripciones')
                    .where('correoEmail', '==', paramMembresia.email)
                    .where('estatus', '==', true).get()
                if (!existSlug.size > 0) {
                    const objectSave = {
                        idUsuario: paramMembresia.uid,
                        correoEmail: paramMembresia.email,
                        nombreMembresia: paramMembresia.nombre,
                        cantidadRestaurantes: paramMembresia.cantidad,
                        descripcionMembresia: paramMembresia.descripcion,
                        encuesta: paramMembresia.encuesta,
                        redes: paramMembresia.redes,
                        subir: paramMembresia.subir,
                        precio: paramMembresia.precio,
                        fechaInicio: paramMembresia.fechaInicio,
                        fechaFin: paramMembresia.fechaFin,
                        estatus: true
                    }
                    const createMembresia = await db.collection("suscripciones").add(objectSave);
                    objectSave.id = createMembresia.id;
                    commit("addSuscripciones", objectSave);
                    return {
                        res: true,
                        error: ""
                    }
                } else {
                    return {
                        res: false,
                        error: "El usuario ya cuenta con una membresia activa"
                    }
                }
            } catch (error) {
                console.log(error);
                return {
                    res: false,
                    error: "Se genero un error al guardar"
                }
            }
        },
        async obtieneMembresiaCurrent({ commit }, paramUser) {
            try {
                const queryMembresia = await db.collection("suscripciones")
                    .where("estatus", '==', true)
                    .where("idUsuario", '==', paramUser.uid)
                    .limit(1).get();
                const listMembresias = []
                queryMembresia.forEach(async elementMembresia => {
                    let objMembresia = {}
                    objMembresia = elementMembresia.data()
                    objMembresia.id = elementMembresia.id
                    listMembresias.push(objMembresia)
                })
                const dataOneMembrecia = (listMembresias.length > 0) ? listMembresias[0] : null;
                commit('setMembrenciaUsuario', dataOneMembrecia);
            } catch (error) {
                console.log(error);
            }
        },
        async desactivarSuscripcion({ commit }, paramMembresia) {
            try {
                await db.collection('suscripciones')
                    .doc(paramMembresia.id).update({
                        estatus: false
                    });
                return {
                    res: true,
                    error: ""
                }
            } catch (error) {
                return {
                    res: false,
                    error: "Se genero un error al finalizar la suscripción"
                }
            }
        }
    },
    getters: {
        membresiaActiva(state) {
            return !!state.membresiaUsuario; 
        }
    },
}