<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import Navbar from "@/components/Navbar";
export default {
  name: "App",
  components: {
    Navbar,
  },
  //  computed: {
  //   muestraNavbar() {
  //     return this.$route.name == 'Menu' ? NavbarRestaurante : Navbar
  //   }
  // },
};
</script>
<style lang="scss">
.container-global {
  padding: 50px 0;
  @media (min-width: 1024px) {
    max-width: 92%;
    margin: 0 auto;
    padding: 80px 0;
  }
}
.v-btn {
  font-weight: normal !important;
}
.v-dialog .v-card__title {
  word-break: break-word !important;
}
.btn-add {
  background-color: #525252;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  padding: 8px 16px;
  font-weight: normal;
  font-size: 14px;
  position: relative;
  text-align: center;
  &.accent {
    background-color: #3f51b5;
  }
  svg {
    margin-right: 16px;
    .color-add {
      fill: none;
      stroke: #ffffff;
      stroke-miterlimit: 10;
      stroke-width: 1.5px;
    }
  }
  .v-image {
    margin-right: 16px;
  }
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &:before {
    left: 0;
    top: 0;
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: #525252;
    opacity: 0;
  }
  &:after {
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 3;
    height: 20px;
    width: 20px;
    border: solid 3px #ffffff;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    opacity: 0;
    @keyframes rotate {
      from {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  }
  &.loading {
    pointer-events: none;
    &:after,
    &.loading:before {
      opacity: 1;
    }
  }
}
.btn-back {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  transition: all 300ms;
  .icon-arrow-left {
    margin-right: 16px;
    transition: all 300ms;
  }
  &:hover,
  &:focus {
    .icon-arrow-left {
      transform: translateX(-8px);
    }
  }
}
.drag-inputFile {
  position: relative;
  width: 350px;
  height: 200px;
  border: 1px dashed #bfbfbf;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.excelBorder {
    border: 1px dashed #008000;
  }
  label.select {
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 2;
    height: 100%;
    cursor: pointer;
  }
  input {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .img-preview {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 2;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #ffffff;
  }
  .file-show {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    flex-direction: column;
    .icon-excel {
      max-width: 50px;
      margin-bottom: 16px;
    }
    .text-file {
      color: #525252;
      margin-bottom: 0;
      max-width: 50%;
      text-align: center;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
    }
  }
  .editBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    margin-bottom: 16px;
    background-color: #009688;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 3;
  }
  .deleteBtn {
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #ff0a0a;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
  .upload {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .icon-camara {
      margin-bottom: 16px;
    }
    .icon-file {
      width: 35px;
      margin-bottom: 16px;
    }
    .label-black {
      background-color: #f5f5f5;
      padding: 8px 25px;
      text-align: center;
      color: #000000;
      font-weight: normal;
      font-size: 15px;
      margin-bottom: 16px;
      &.excel {
        background-color: #008000;
        color: #ffffff;
      }
    }
    .label-instruccion {
      color: #616161e5;
      margin-bottom: 0;
      font-size: 12px;
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
}
.negroClaro {
  color: rgba(0, 0, 0, 0.58);
}

.listGeneralCategory {
  list-style: none;
  padding: 0 !important;
  li {
    padding: 8px;
    padding-left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    min-height: 50px;
  }
}
.opcionalText {
  vertical-align: top;
}
.buttons-socials {
  display: flex;
  align-items: center;
}
.link-olvide {
  color: #000000;
  text-decoration: none;
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
}

.swal2-popup,
.swal2-title {
  font-family: "Roboto" !important;
}

.swiper-button-next,
.swiper-button-prev {
  position: static !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #808080;
  transform: none !important;
  &:after {
    display: none !important;
  }
  svg {
    width: 12px;
    height: 12px;
    fill: #808080;
  }
}

.swiper-button-prev {
  margin-right: 8px !important;
}

.buttons-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.container-fraction {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .current-fraction {
    color: #000000;
  }
  .total-fraction {
    color: #a5a4a4;
  }
}

.btn-outline-black {
  border: 1px solid #000;
  text-decoration: none;
  border-radius: 30px;
  padding: 8px 32px;
}
.btn-orange {
  &:before {
    background-color: #f59025;
    opacity: 1;
  }
  &:hover,
  &:focus {
    .v-btn__content {
      color: #ffffff;
    }
    &:before {
      background-color: #2b604b;
      opacity: 1 !important;
    }
  }
}
.btn-outline-white {
  border: 1px solid #ffffff;
  &:before {
    background-color: transparent;
    opacity: 1;
  }
  &:hover,
  &:focus {
    border: 1px solid #ffbf94;
    .v-btn__content {
      color: #ffffff;
    }
    &:before {
      background-color: #ffbf94;
      opacity: 1 !important;
    }
  }
}

.btn-outline-black {
  &:before {
    background-color: transparent;
  }
  &.hover-green {
    &:hover,
    &:focus {
      border: 1px solid #2b604b;
      .v-btn__content {
        color: #ffffff;
      }
      &:before {
        background-color: #2b604b;
        opacity: 1 !important;
      }
    }
  }
  &:hover,
  &:focus {
    border: 1px solid #ffbf94;
    .v-btn__content {
      color: #ffffff;
    }
    &:before {
      background-color: #ffbf94;
      opacity: 1 !important;
    }
  }
}

.container-autenticacion {
  width: 100%;
  padding: 50px 0;
  background-color: rgba($color: #ffbf94, $alpha: 0.1);
  min-height: 100%;
  .img-container {
    width: 80%;
    margin: 0 auto;
    /* Medium and up */
    @media screen and (min-width: 40em) {
      width: 90%;
      margin: 0;
    }
  }
}
.container-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}
.swiper-pagination {
  position: static !important;
  width: auto !important;
  .swiper-pagination-bullet {
    left: unset !important;
    right: unset !important;
    width: 12px;
    height: 12px;
    transform: none !important;
    background-color: #2b604b;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>