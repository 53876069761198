import { firebase, auth, db, storage, marcaTiempo, functions } from "@/firebase";
export default {
    namespaced: true,
    state: {
        listMembresias: [],
        membresiaUsuario: null
    },
    mutations: {
        addMembresia(state, payload) {
            state.listMembresias.push(payload)
        },
        setMembresias(state, payload) {
            state.listMembresias = payload
        },
        updateMembresia(state, payload) {
            state.listMembresias = state.listMembresias.map(item => item.id === payload.id ? payload : item)
        },
        setMembrenciaUsuario(state, payload) {
            state.membresiaUsuario = payload;
        },
        deletePlatillo(state, payload) {
            state.listMembresias = state.listMembresias.filter(item => item.id !== payload.id)
        }
    },
    actions: {
        async crearMembresia({ commit, state, rootState }, paramMembresia) {
            try {
                let membresiaObject = {
                    nombre: paramMembresia.nombre,
                    descripcion: paramMembresia.descripcion,
                    duracion: paramMembresia.duracion,
                    precio: paramMembresia.precio,
                    cantidad: paramMembresia.cantidad,
                    encuesta: paramMembresia.encuesta,
                    subir: paramMembresia.subir,
                    redes: paramMembresia.redes,
                    estatus: true,
                    urlImage: ""
                }
                const createMembresia = await db.collection('membresias').add(membresiaObject);
                membresiaObject.id = createMembresia.id;
                // valido que haya imagen para almacenarla
                if (paramMembresia.imagen && paramMembresia.imagen.size > 0) {
                    // const imgName = paramMembresia.imagen.name.substr(0, paramMembresia.imagen.name.lastIndexOf('.'));
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('membresias').child(membresiaObject.id)
                    const res = await refImagen.put(paramMembresia.imagen)
                    const urlDescarga = await refImagen.getDownloadURL()
                    membresiaObject.urlImage = urlDescarga
                    //actualiza foto del restaurante
                    await db.collection('membresias').doc(membresiaObject.id).update({
                        urlImage: urlDescarga
                    });
                }
                commit('addMembresia', membresiaObject);
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                console.log(error);
                return {
                    res: false,
                    error: 'Se genero un error.'
                }
            }
        },
        async obtieneMembresias({ commit }) {
            try {
                const query = await db.collection('membresias').get()
                const list = []
                query.forEach(element => {
                    let membresia = element.data()
                    membresia.id = element.id
                    list.push(membresia)
                });
                commit('setMembresias', list)
                return {
                    res: true
                }
            } catch (error) {
                return {
                    res: false,
                    error: error
                }
            }
        },
        async actualizarMembresias({ commit, state, rootState }, paramMembresia) {
            try {
                let objMembresia = {
                    nombre: paramMembresia.nombre,
                    cantidad: paramMembresia.cantidad,
                    descripcion: paramMembresia.descripcion,
                    encuesta: paramMembresia.encuesta,
                    duracion: paramMembresia.duracion,
                    precio: paramMembresia.precio,
                    redes: paramMembresia.redes,
                    subir: paramMembresia.subir,
                    urlImage: paramMembresia.urlImage
                }
                // valido que haya imagen para almacenarla
                if (paramMembresia.imagen && paramMembresia.imagen.size > 0) {
                    const refImagen = storage.ref().child(rootState.usuario.email)
                        .child('membresias').child(paramMembresia.id)
                    const res = await refImagen.put(paramMembresia.imagen)
                    const urlDescarga = await refImagen.getDownloadURL()
                    objMembresia.urlImage = urlDescarga
                }
                await db.collection('membresias')
                    .doc(paramMembresia.id).update(objMembresia)
                commit('updateMembresia', paramMembresia);
                return {
                    res: true,
                    error: ''
                }
            } catch (error) {
                console.log(error);
                return {
                    res: false,
                    error: error
                }
            }
        },
        async obtieneMembresiaCurrent({ commit }, paramUser) {
            try {
                const queryMembresia = await db.collection("suscripciones")
                    .where("estatus", '==', true)
                    .where("idUsuario", '==', paramUser.uid)
                    .limit(1).get();
                const listMembresias = []
                queryMembresia.forEach(async elementMembresia => {
                    let objMembresia = {}
                    objMembresia = elementMembresia.data()
                    objMembresia.id = elementMembresia.id
                    listMembresias.push(objMembresia)
                })
                const dataOneMembrecia = (listMembresias.length > 0) ? listMembresias[0] : null;
                commit('setMembrenciaUsuario', dataOneMembrecia);
            } catch (error) {
                console.log(error);
            }
        },
    }
}