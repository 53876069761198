import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1976D2', // #E53935
                secondary: "#fbff00", // #FFCDD2
                accent: colors.indigo.base, // #3F51B5
                three: "#4AA96C",
                removeColor: colors.deepOrange.lighten4,
                green: colors.green,
                errorColor: "#ff0a0a",
                headerTable: "#f5f5f5",
                navMenu: colors.blueGrey.darken1,
                buttonPrimary: "#070707",
                btnSuccess: colors.teal,
                btnAdd: "#525252",
                verde: "#2B604B",
                verdeOscuro: "#ff0000",
                blanco: "#ffffff",
                grayLight: colors.grey.lighten4,
                orangeMe: "#f59025",
                greenOpacity: "#2b604b"
            },
        },
    },
});
