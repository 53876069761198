<template>
  <div class="bloque-caracteristicas" id="Caracteristicas">
    <v-container>
      <div class="container-title mb-6">
        <h3 class="text-h4 text-md-h3 black--text">Características</h3>
        <p class="text-body-2 black--text"></p>
      </div>
      <div class="slider-caracteristicas">
        <div class="swiper-container swiper-caracteristicas">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in listCaracteristicas"
              :key="index"
            >
              <div class="card-caracteristica pa-sm-4 pa-lg-6 mb-6">
                <div class="img-caracteristica mb-6">
                  <v-img
                    alt="logo caracteristicas"
                    class="shrink"
                    contain
                    :src="item.img"
                    width="100%"
                  />
                </div>
                <h5 class="text-h5 title-caracteristicas mb-2">
                  {{ item.titulo }}
                </h5>
                <p class="text-body-2 texto-caracteristicas">
                  {{ item.texto }}
                </p>
              </div>
            </div>
          </div>
          <div class="container-pagination">
            <div class="paginationCaracteristicas swiper-pagination"></div>
          </div>
        </div>
      </div>
      <v-row class="list-cards-caracteristicas">
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
          v-for="(item, index) in listCaracteristicas"
          :key="index"
        >
          <div class="card-caracteristica pa-sm-4 pa-lg-6 mb-6">
            <div class="img-caracteristica mb-6">
              <v-img
                alt="logo caracteristicas"
                class="shrink"
                contain
                :src="item.img"
                width="100%"
              />
            </div>
            <h5 class="text-h5 title-caracteristicas mb-2">
              {{ item.titulo }}
            </h5>
            <p class="text-body-2 texto-caracteristicas">{{ item.texto }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Navigation, Pagination]);

export default {
  data() {
    return {
      listCaracteristicas: [
        {
          img: require("@/assets/icon-woman.svg"),
          titulo: "Fácil de usar",
          texto:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mi nibh, maximus ut gravida",
        },
        {
          img: require("@/assets/icon-woman.svg"),
          titulo: "Fácil de usar",
          texto:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mi nibh, maximus ut gravida",
        },
        {
          img: require("@/assets/icon-woman.svg"),
          titulo: "Fácil de usar",
          texto:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mi nibh, maximus ut gravida",
        },
        {
          img: require("@/assets/icon-woman.svg"),
          titulo: "Fácil de usar",
          texto:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mi nibh, maximus ut gravida",
        },
        {
          img: require("@/assets/icon-woman.svg"),
          titulo: "Fácil de usar",
          texto:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mi nibh, maximus ut gravida",
        },
        {
          img: require("@/assets/icon-woman.svg"),
          titulo: "Fácil de usar",
          texto:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mi nibh, maximus ut gravida",
        },
      ],
    };
  },
  mounted() {
    const sliderCaracteristicas = new Swiper(".swiper-caracteristicas", {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 16,
      pagination: {
        el: ".paginationCaracteristicas",
        dynamicBullets: true,
        clickable: true,
      },
    });
  },
};
</script>
<style lang="scss">
.bloque-caracteristicas {
  width: 100%;
  padding: 60px 0;
  .container-title {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .slider-caracteristicas {
    width: 100%;
    /* Medium and up */
    @media screen and (min-width: 40em) {
      display: none;
    }
  }
  .list-cards-caracteristicas {
    width: 100%;
    display: none;
    /* Medium and up */
    @media screen and (min-width: 40em) {
      display: flex;
    }
  }
  .card-caracteristica {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: all 300ms;
    border-radius: 5px;
    .img-caracteristica {
      width: 80%;
    }
    &:hover {
      background-color: #f59025;
      color: #ffffff;
    }
  }
  /* Medium and up */
  @media screen and (min-width: 40em) {
    padding: 120px 0;
  }
}
</style>