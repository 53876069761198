<template>
  <nav>
    <v-app-bar :color="obtieneColor" app dark fixed height="80px">
      <v-container>
<template
        v-if="
          !usuarioAutenticado &&
          ($route.name == 'Home' ||
            $route.name == 'Login' ||
            $route.name == 'Registro' ||
            $route.name == 'RecuperarContrasenia' ||
            $route.name == 'FAQS')
        "
      >
        <div class="container-navbar">
          <div class="d-flex justify-space-between align-center">
            <router-link to="/">
              <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                :src="require('@/assets/logo-big-tumenu.svg')"
                width="120"
              />
            </router-link>
            <ul class="list-menu-landing text-button">
              <li>
                <router-link to="/faqs" class="black--text">FAQ´s</router-link>
              </li>
              <li>
                <anchor-router-link
                  :to="{ name: 'Home', hash: '#Caracteristicas' }"
                  :scrollOptions="{
                    container: 'body',
                    duration: 700,
                    easing: 'ease',
                  }"
                  class="black--text"
                  >Características</anchor-router-link
                >
              </li>
              <li>
                <anchor-router-link
                  :to="{ name: 'Home', hash: '#Testimonios' }"
                  :scrollOptions="{
                    container: 'body',
                    duration: 700,
                    easing: 'ease',
                  }"
                  class="black--text"
                  >Testimonios</anchor-router-link
                >
              </li>
              <li>
                <anchor-router-link
                  :to="{ name: 'Home', hash: '#Paquetes' }"
                  :scrollOptions="{
                    container: 'body',
                    duration: 700,
                    easing: 'ease',
                  }"
                  class="black--text"
                  >Paquetes</anchor-router-link
                >
              </li>
            </ul>
            <div class="buttons-nav">
              <div class="d-flex align-center">
                <v-btn
                  to="/login"
                  color="black"
                  class="black--text py-7 pr-9 pl-9 mr-2 btn-outline-black"
                  outlined
                  rounded
                  >Iniciar Sesión</v-btn
                >
                <v-btn
                  to="/registro"
                  class="white--text py-7 pr-9 pl-9 btn-orange"
                  rounded
                  >Registrarme</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex flex-grow-1 align-center">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            :src="muestraLogo"
            width="60"
            v-if="$route.name == 'Menu' || $route.name == 'ResponderEncuesta'"
          />
          <v-spacer></v-spacer>
          <div
            class="buttons-socials"
            v-if="$route.name == 'Menu' || $route.name == 'ResponderEncuesta'"
          >
            <v-btn
              :href="facebook"
              v-if="facebook"
              target="_blank"
              icon
              class="white--text"
            >
              <v-icon size="28px" class="editBtn">mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              :href="instagram"
              v-if="instagram"
              target="_blank"
              icon
              class="white--text"
            >
              <v-icon size="28px" class="editBtn">mdi-instagram</v-icon>
            </v-btn>
          </div>
          <v-btn
            color="grayLight"
            class="black--text"
            @click.prevent="cerrarSesion()"
            v-if="
              usuarioAutenticado &&
              $route.name !== 'Menu' &&
              $route.name !== 'Login'
            "
            >CERRAR SESIÓN</v-btn
          >
        </div>
      </template>
      </v-container>
      
    </v-app-bar>
    <v-navigation-drawer
      color="verde"
      v-model="drawer"
      app
      v-if="usuarioAutenticado"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div class="d-flex justify-center">
              <img
                width="40%"
                :src="require('../assets/logo-tumenu.png')"
                alt="icon edit"
              />
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav v-if="usuario.claims && usuario.claims.customer">
        <v-list-item to="/restaurantes">
          <v-list-item-title class="text-center white--text text-subtitle-2"
            >MIS RESTAURANTES</v-list-item-title
          >
        </v-list-item>
        <!-- <v-list-item to="/encuestas">
          <v-list-item-title class="text-center white--text text-subtitle-2"
            >MIS ENCUESTAS</v-list-item-title
          >
        </v-list-item>
        <v-list-item to="/perfil">
          <v-list-item-title class="text-center white--text text-subtitle-2"
            >PERFIL</v-list-item-title
          >
        </v-list-item> -->
      </v-list>
      <v-list nav v-if="usuario.claims && usuario.claims.admin">
        <v-list-item-group>
          <v-list-item to="/usuarios" active-class="primary--text">
            <v-list-item-title class="text-center white--text text-subtitle-2"
              >Usuarios</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/membresias" active-class="primary--text">
            <v-list-item-title class="text-center white--text text-subtitle-2"
              >Membresias</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/suscripciones" active-class="primary--text">
            <v-list-item-title class="text-center white--text text-subtitle-2"
              >Suscripciones</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script>
import AnchorRouterLink from "vue-anchor-router-link";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      mobile: null,
      drawer: true,
      selectedItem: 0,
      listMenuVendedor: [
        {
          title: "Restaurantes",
          icon: "mdi-view-dashboard",
          to: { name: "" },
          subLinks: [
            {
              title: "Todos los restaurantes",
              icon: "mdi-view-dashboard",
              to: { name: "Restaurantes" },
            },
            {
              title: "Crear restaurante",
              icon: "mdi-view-dashboard",
              to: { name: "CrearRestaurante" },
            },
          ],
        },
        {
          title: "Cerrar Sesion",
          icon: "mdi-help-box",
          to: {},
          action: "logout",
        },
      ],
      listMenuAdmin: [
        {
          title: "Usuarios",
          icon: "mdi-account-circle",
          to: { name: "Usuarios" },
          active: true,
        },
        {
          title: "Cerrar Sesion",
          icon: "mdi-logout",
          to: {},
          action: "logout",
          active: false,
        },
      ],
    };
  },
  created() {
    this.checkScreen();
  },
  components: {
    AnchorRouterLink,
  },
  computed: {
    ...mapGetters(["usuarioAutenticado"]),
    ...mapState(["usuario"]),
    ...mapState(["logoNav", "colorNav", "facebook", "instagram"]),
    ...mapState("adminSuscripciones", ["membresiaUsuario"]),
    muestraLogo() {
      return this.logoNav ? this.logoNav : "";
    },
    obtieneColor() {
      return this.$route.name == "Menu" ||
        this.$route.name == "ResponderEncuesta"
        ? this.colorNav
        : "blanco";
    },
  },
  methods: {
    ...mapActions(["cerrarSesion"]),
    menuActionClick(action) {
      switch (action) {
        case "logout":
          this.cerrarSesion();
          break;
      }
    },
    checkScreen() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
    },
  },
};
</script>
<style lang="scss">
.container-navbar {
  width: 100%;
}
.v-toolbar__content {
  padding: 0 !important;
  max-width: 92%;
  margin: 0 auto;
}
.v-list .v-list-item--active {
  background-color: #103526;
}
.list-menu-landing {
  list-style: none;
  display: none;
  li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 25px;
    }
  }
  a {
    text-decoration: none;
  }
  /* Large and up */
  @media screen and (min-width: 64em) {
    display: block;
  }
}
.buttons-nav {
  display: none;
  /* Medium and up */
  @media screen and (min-width: 40em) {
    display: block;
  }
}
</style>