var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('v-app-bar',{attrs:{"color":_vm.obtieneColor,"app":"","dark":"","fixed":"","height":"80px"}},[_c('v-container',[(
          !_vm.usuarioAutenticado &&
          (_vm.$route.name == 'Home' ||
            _vm.$route.name == 'Login' ||
            _vm.$route.name == 'Registro' ||
            _vm.$route.name == 'RecuperarContrasenia' ||
            _vm.$route.name == 'FAQS')
        )?[_c('div',{staticClass:"container-navbar"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Vuetify Logo","contain":"","src":require('@/assets/logo-big-tumenu.svg'),"width":"120"}})],1),_c('ul',{staticClass:"list-menu-landing text-button"},[_c('li',[_c('router-link',{staticClass:"black--text",attrs:{"to":"/faqs"}},[_vm._v("FAQ´s")])],1),_c('li',[_c('anchor-router-link',{staticClass:"black--text",attrs:{"to":{ name: 'Home', hash: '#Caracteristicas' },"scrollOptions":{
                    container: 'body',
                    duration: 700,
                    easing: 'ease',
                  }}},[_vm._v("Características")])],1),_c('li',[_c('anchor-router-link',{staticClass:"black--text",attrs:{"to":{ name: 'Home', hash: '#Testimonios' },"scrollOptions":{
                    container: 'body',
                    duration: 700,
                    easing: 'ease',
                  }}},[_vm._v("Testimonios")])],1),_c('li',[_c('anchor-router-link',{staticClass:"black--text",attrs:{"to":{ name: 'Home', hash: '#Paquetes' },"scrollOptions":{
                    container: 'body',
                    duration: 700,
                    easing: 'ease',
                  }}},[_vm._v("Paquetes")])],1)]),_c('div',{staticClass:"buttons-nav"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"black--text py-7 pr-9 pl-9 mr-2 btn-outline-black",attrs:{"to":"/login","color":"black","outlined":"","rounded":""}},[_vm._v("Iniciar Sesión")]),_c('v-btn',{staticClass:"white--text py-7 pr-9 pl-9 btn-orange",attrs:{"to":"/registro","rounded":""}},[_vm._v("Registrarme")])],1)])],1)])]:[_c('div',{staticClass:"d-flex flex-grow-1 align-center"},[(_vm.$route.name == 'Menu' || _vm.$route.name == 'ResponderEncuesta')?_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Vuetify Logo","contain":"","src":_vm.muestraLogo,"width":"60"}}):_vm._e(),_c('v-spacer'),(_vm.$route.name == 'Menu' || _vm.$route.name == 'ResponderEncuesta')?_c('div',{staticClass:"buttons-socials"},[(_vm.facebook)?_c('v-btn',{staticClass:"white--text",attrs:{"href":_vm.facebook,"target":"_blank","icon":""}},[_c('v-icon',{staticClass:"editBtn",attrs:{"size":"28px"}},[_vm._v("mdi-facebook")])],1):_vm._e(),(_vm.instagram)?_c('v-btn',{staticClass:"white--text",attrs:{"href":_vm.instagram,"target":"_blank","icon":""}},[_c('v-icon',{staticClass:"editBtn",attrs:{"size":"28px"}},[_vm._v("mdi-instagram")])],1):_vm._e()],1):_vm._e(),(
              _vm.usuarioAutenticado &&
              _vm.$route.name !== 'Menu' &&
              _vm.$route.name !== 'Login'
            )?_c('v-btn',{staticClass:"black--text",attrs:{"color":"grayLight"},on:{"click":function($event){$event.preventDefault();return _vm.cerrarSesion()}}},[_vm._v("CERRAR SESIÓN")]):_vm._e()],1)]],2)],1),(_vm.usuarioAutenticado)?_c('v-navigation-drawer',{attrs:{"color":"verde","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_c('div',{staticClass:"d-flex justify-center"},[_c('img',{attrs:{"width":"40%","src":require('../assets/logo-tumenu.png'),"alt":"icon edit"}})])])],1)],1),_c('v-divider'),(_vm.usuario.claims && _vm.usuario.claims.customer)?_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',{attrs:{"to":"/restaurantes"}},[_c('v-list-item-title',{staticClass:"text-center white--text text-subtitle-2"},[_vm._v("MIS RESTAURANTES")])],1)],1):_vm._e(),(_vm.usuario.claims && _vm.usuario.claims.admin)?_c('v-list',{attrs:{"nav":""}},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"to":"/usuarios","active-class":"primary--text"}},[_c('v-list-item-title',{staticClass:"text-center white--text text-subtitle-2"},[_vm._v("Usuarios")])],1),_c('v-list-item',{attrs:{"to":"/membresias","active-class":"primary--text"}},[_c('v-list-item-title',{staticClass:"text-center white--text text-subtitle-2"},[_vm._v("Membresias")])],1),_c('v-list-item',{attrs:{"to":"/suscripciones","active-class":"primary--text"}},[_c('v-list-item-title',{staticClass:"text-center white--text text-subtitle-2"},[_vm._v("Suscripciones")])],1)],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }