<template>
  <div class="bloque-registrate">
    <v-container>
      <div class="container-img-float">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/hombreSentado.png')"
          width="100%"
          height="100%"
        />
      </div>
      <div class="container-text">
        <div class="d-flex justify-center">
          <h2
            class="
              text-h4 text-md-h3
              mb-16
              text-center
              font-weight-bold
              white--text
            "
          >
            Registrate Lorem ipsum dolor sitolor Lorem ipsum dolor
          </h2>
        </div>
        <div class="d-flex flex-column flex-sm-row justify-center">
          <v-btn
            to="/registro"
            color="orangeMe"
            class="white--text py-7 pr-9 pl-9 mb-4 mb-sm-0 mr-sm-6"
            rounded
            >Registrarme</v-btn
          >
          <v-btn
            to="/"
            class="py-7 pr-9 pl-9 btn-outline-white"
            background-color="transparent"
            outlined
            rounded
            ><span class="white--text">Compartir</span></v-btn
          >
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.bloque-registrate {
  background-color: #2b604b;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 50px;
  min-height: 500px;
  position: relative;
  .container-img-float {
    width: 80%;
    position: absolute;
    top: -180px;
    left: 50%;
    transform: translateX(-50%);
    height: 300px;
    /* Large and up */
    @media screen and (min-width: 64em) {
      width: 26%;
      height: 350px;
    }
  }
  .container-text {
    width: 80%;
    margin: 0 auto;
  }
  /* Medium and up */
  @media screen and (min-width: 40em) {
    padding-top: 250px;
    padding-bottom: 80px;
  }
}
</style>