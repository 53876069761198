import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions"


var firebaseConfig = {
    apiKey: "AIzaSyDNHQGFXesVZKdyBlyxQ-LgwJhzB_8wthA",
    authDomain: "restaurant-app-ab494.firebaseapp.com",
    projectId: "restaurant-app-ab494",
    storageBucket: "restaurant-app-ab494.appspot.com",
    messagingSenderId: "966951753853",
    appId: "1:966951753853:web:de107e5905dabaa04fb914"

};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions()
const marcaTiempo = firebase.firestore.FieldValue.serverTimestamp;

export { firebase, db, auth, storage, marcaTiempo, functions }