<template>
  <div class="bloque-testimonios" id="Testimonios">
    <v-container>
      <div class="d-flex justify-center mb-6">
        <h3 class="text-h4 text-md-h3 black--text">Testimonios</h3>
      </div>
      <div class="container-fraction text-subtitle-1 mb-3">
        <span class="current-fraction">{{ currentText }}/</span>
        <span class="total-fraction">{{currentTotal}}</span>
      </div>
      <div class="swiper-container swiper-testimonios">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in listTestimonios"
            :key="index"
          >
            <div class="card-testimonio px-6 py-6 px-md-8 py-md-12 mb-6">
              <div class="img-comillas mb-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35.449"
                  height="33.58"
                  viewBox="0 0 35.449 33.58"
                >
                  <path
                    data-name="Trazado 10073"
                    d="M18.756-89.525q-4.447.838-6.252 6.445a17.622 17.622 0 0 0-.9 5.479 3.954 3.954 0 0 0 .032.548q.032.226.1 1h7.025V-62H5.027v-12.959q0-9.539 3.8-14.7a14.114 14.114 0 0 1 9.926-5.93zm21.721 0a7.227 7.227 0 0 0-5.382 4 16.346 16.346 0 0 0-1.837 7.863q0 .387.032.773a4.4 4.4 0 0 0 .161.838h7.025V-62H26.684v-12.959a28.587 28.587 0 0 1 3.223-13.7q3.223-6.026 10.57-6.929z"
                    transform="translate(-5.027 95.584)"
                    style="fill: #f59025"
                  />
                </svg>
              </div>
              <p class="text-body-1 black--text texto-testimonio">
                {{ item.texto }}
              </p>
              <div class="footer-info">
                <p class="text-subtitle-1 mb-0">{{ item.autor }}</p>
                <p class="text-caption mb-0">{{ item.subtexto }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons-flex mt-8">
          <div class="swiper-button-prev prevTestimonio">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.788"
              height="11.046"
              viewBox="0 0 6.788 11.046"
            >
              <path
                data-name="Trazado 1719"
                d="m18 6.2 4 4-4 4"
                transform="rotate(-178 11.553 8.003)"
                style="
                  fill: none;
                  stroke: #000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 2px;
                "
              />
            </svg>
          </div>
          <div class="swiper-button-next nextTestimonio">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.414"
              height="10.829"
              viewBox="0 0 6.414 10.829"
            >
              <path
                data-name="Trazado 1718"
                d="m18 6.2 4 4-4 4"
                transform="translate(-16.586 -4.783)"
                style="
                  fill: none;
                  stroke: #000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 2px;
                "
              />
            </svg>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Navigation, Pagination]);

export default {
  data() {
    return {
      currentSlide: 1,
      listTestimonios: [
        {
          texto:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore Lorem ipsum dolor sit amet",
          autor: "Omar Flores",
          subtexto: "Lorem ipsum dolor sit amet,",
        },
        {
          texto:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore Lorem ipsum dolor sit amet",
          autor: "Omar Flores",
          subtexto: "Lorem ipsum dolor sit amet,",
        },
        {
          texto:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore Lorem ipsum dolor sit amet",
          autor: "Omar Flores",
          subtexto: "Lorem ipsum dolor sit amet,",
        },
        {
          texto:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore Lorem ipsum dolor sit amet",
          autor: "Omar Flores",
          subtexto: "Lorem ipsum dolor sit amet,",
        },
        {
          texto:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore Lorem ipsum dolor sit amet",
          autor: "Omar Flores",
          subtexto: "Lorem ipsum dolor sit amet,",
        },
        {
          texto:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore Lorem ipsum dolor sit amet",
          autor: "Omar Flores",
          subtexto: "Lorem ipsum dolor sit amet,",
        },
      ],
    };
  },
  computed: {
    currentText: function (){
       return this.currentSlide >= 10
        ? this.currentSlide
        : "0" + this.currentSlide;
    },
    currentTotal: function () {
      return this.listTestimonios.length >= 10
        ? this.listTestimonios.length
        : "0" + this.listTestimonios.length;
    },
  },
  mounted() {
    const sliderTestimonio = new Swiper(".swiper-testimonios", {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 16,
      breakpoints: {
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1080: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 3,
          centeredSlides: true,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: ".nextTestimonio",
        prevEl: ".prevTestimonio",
      },
    });
    sliderTestimonio.on("slideChange", function () {
      let numSlide = sliderTestimonio.realIndex + 1;
      this.currentSlide = numSlide;
    });
  },
};
</script>
<style lang="scss">
.bloque-testimonios {
  background-color: #fafafa;
  width: 100%;
  min-height: 500px;
  padding: 60px 0;
  .swiper-testimonios .swiper-slide {
    opacity: 0.5;
    height: auto;
    display: flex;
    align-items: stretch;
    &.swiper-slide-active {
      opacity: 1;
      .card-testimonio {
        top: 0;
      }
    }
  }
  .card-testimonio {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: rgba($color: #f59025, $alpha: 0.2);
    box-sizing: border-box;
    position: relative;
    top: -1rem;
    margin-top: 2rem;
    border-radius: 5px;
    transition: all 400ms;
    .img-comillas {
      width: 30px;
      svg {
        width: 100%;
      }
    }
    .texto-testimonio {
      width: 100%;
      min-height: 160px;
      flex: 1 0 auto;
    }
  }
  /* Medium and up */
  @media screen and (min-width: 40em) {
    padding: 120px 0;
  }
}
</style>