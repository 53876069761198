<template>
  <div class="bloque-funciona">
    <v-container>
      <div class="container-text-head">
        <div class="d-flex flex-column justify-center text-center mb-16">
          <h3 class="text-h4 text-md-h3 black--text mb-4">¿Cómo funciona?</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mi nibh,
            maximus ut gravida.
          </p>
        </div>
      </div>
      <div class="container-slider">
        <v-row class="align-sm-center">
          <v-col xs="12" sm="6" md="6" lg="6">
            <div class="swiper-container sliderFuncionaImg">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="img-slider-funciona">
                    <v-img
                      alt="logo caracteristicas"
                      class="shrink"
                      contain
                      :src="require('@/assets/celular2.png')"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="img-slider-funciona">
                    <v-img
                      alt="logo caracteristicas"
                      class="shrink"
                      contain
                      :src="require('@/assets/banner-home.png')"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="img-slider-funciona">
                    <v-img
                      alt="logo caracteristicas"
                      class="shrink"
                      contain
                      :src="require('@/assets/crear-menu.png')"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col xs="12" sm="6" md="6" lg="6">
            <div class="container-info px-4 px-md-10">
              <div class="swiper-container sliderTxtFunciona">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="item-funciona">
                      <h3 class="text-h4 title-funciona mb-4">
                        1. Abre tu cámara
                      </h3>
                      <p class="black--text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        In mi nibh, maximus ut gravida.
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="item-funciona">
                      <h3 class="text-h4 title-funciona mb-4">
                        2. Escanea el código
                      </h3>
                      <p class="black--text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        In mi nibh, maximus ut gravida.
                      </p>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="item-funciona">
                      <h3 class="text-h4 title-funciona mb-4">
                        3. Listo! tu menú esta en tu celular
                      </h3>
                      <p class="black--text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        In mi nibh, maximus ut gravida.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="container-pagination mt-sm-12 mt-lg-0">
          <div class="paginationImgFunciona swiper-pagination"></div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import Swiper, {
  Navigation,
  Pagination,
  Controller,
  EffectFade,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Navigation, Pagination, Controller, EffectFade, Autoplay]);
export default {
  mounted() {
    const swiperFuncionaImg = new Swiper(".sliderFuncionaImg", {
      slidesPerView: 1,
      spaceBetween: 16,
      effect: "fade",
      loop: true,
      autoplay: {
        delay: 3200,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".paginationImgFunciona",
        dynamicBullets: true,
        clickable: true,
      },
    });
    const sliderTxtFunciona = new Swiper(".sliderTxtFunciona", {
      slidesPerView: 1,
      spaceBetween: 16,
      effect: "fade",
      loop: true,
    });
    swiperFuncionaImg.controller.control = sliderTxtFunciona;
    sliderTxtFunciona.controller.control = swiperFuncionaImg;
  },
};
</script>
<style lang="scss">
.bloque-funciona {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  background-color: rgba($color: #ffbf94, $alpha: 0.15);
  .container-text-head {
    width: 100%;
    /* Medium and up */
    @media screen and (min-width: 40em) {
      width: 50%;
      margin: 0 auto;
    }
  }
  .title-funciona {
    color: #2b604b;
  }
  .container-slider {
    position: relative;
  }
  .swiper-slide {
    .img-slider-funciona,
    .item-funciona {
      opacity: 0;
      transition: all 300ms;
    }
    &.swiper-slide-active {
      .img-slider-funciona,
      .item-funciona {
        opacity: 1;
      }
    }
  }
  /* Large and up */
  @media screen and (min-width: 64em) {
    .container-pagination {
      width: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      right: -4%;
      transform: translateY(-50%) rotate(90deg);
    }
  }
  /* Medium and up */
  @media screen and (min-width: 40em) {
    padding-top: 200px;
    padding-bottom: 120px;
  }
}
.img-slider-funciona {
  width: 80%;
  margin: 0 auto;
  height: 250px;
  /* Medium and up */
  @media screen and (min-width: 40em) {
    height: 400px;
  }
}
.item-funciona {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>