<template>
  <div class="bloque-planes" id="Paquetes">
    <v-container>
      <div class="d-flex justify-center mb-12">
        <h3 class="text-h4 text-md-h3 black--text">Paquetes</h3>
      </div>
      <div class="sliderCardsPlanes">
        <div class="swiper-container swiper-planes">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in listPlanes"
              :key="index"
            >
              <!-- list planes -->
              <div class="column-plan">
                <div class="row-title-plan">
                  <h5 class="text-h5 mb-2">{{ item.titulo }}</h5>
                  <h5 class="text-h5">{{ item.precio }}</h5>
                </div>
                <div
                  class="row-data"
                  v-for="(cat, index) in item.caracteristicas"
                  :key="index"
                >
                  <p class="text-subtitle-1">{{ cat.name }}</p>
                  <div class="icon-container">
                    <div class="icon-check" v-if="cat.status">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                      >
                        <g data-name="Grupo 5703">
                          <g
                            data-name="Elipse 81"
                            style="fill: #2b604b; stroke: #2b604b"
                          >
                            <circle
                              cx="21"
                              cy="21"
                              r="21"
                              style="stroke: none"
                            />
                            <circle
                              cx="21"
                              cy="21"
                              r="20.5"
                              style="fill: none"
                            />
                          </g>
                          <g data-name="Grupo 5660">
                            <path
                              data-name="Trazado 2965"
                              d="m4244.24 7137-9.7 10.182-3.846-3.948"
                              transform="translate(-4216.467 -7121.091)"
                              style="
                                stroke: #fff;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 4px;
                                fill: none;
                              "
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="icon-uncheck" v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26.917"
                        height="27.83"
                        viewBox="0 0 26.917 27.83"
                      >
                        <g data-name="Grupo 5705">
                          <path
                            data-name="Trazado 2964"
                            d="m4238 7137 18.435 19.348"
                            transform="translate(-4233.758 -7132.758)"
                            style="
                              fill: none;
                              stroke: #a5a4a4;
                              stroke-linecap: round;
                              stroke-width: 6px;
                            "
                          />
                          <path
                            data-name="Trazado 2965"
                            d="M4256.435 7137 4238 7156.348"
                            transform="translate(-4233.758 -7132.758)"
                            style="
                              fill: none;
                              stroke: #a5a4a4;
                              stroke-linecap: round;
                              stroke-width: 6px;
                            "
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="row-data-button">
                  <v-btn
                    to="/registro"
                    color="black"
                    class="
                      black--text
                      py-4 py-sm-7
                      pr-9
                      pl-9
                      mr-2
                      btn-outline-black
                      hover-green
                    "
                    outlined
                    rounded
                    >Empieza gratis</v-btn
                  >
                </div>
              </div>
              <!-- list planes -->
            </div>
          </div>
        </div>
        <div class="container-pagination mt-12">
          <div class="paginationPlanes swiper-pagination"></div>
        </div>
      </div>
      <div class="flex-cards-planes">
        <div class="column-caracteristicas">
          <div class="row-title-plan"></div>
          <div
            class="row-data"
            v-for="(item, index) in listCaracteristicas"
            :key="index"
          >
            {{ item.titulo }}
          </div>
          <div class="row-data-button"></div>
        </div>
        <!-- list planes -->
        <div
          class="column-plan"
          v-for="(item, index) in listPlanes"
          :key="index"
        >
          <div class="row-title-plan">
            <h5 class="text-h5 mb-2">{{ item.titulo }}</h5>
            <h5 class="text-h5">{{ item.precio }}</h5>
          </div>
          <div
            class="row-data"
            v-for="(cat, index) in item.caracteristicas"
            :key="index"
          >
            <div class="icon-container">
              <div class="icon-check" v-if="cat.status">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                >
                  <g data-name="Grupo 5703">
                    <g
                      data-name="Elipse 81"
                      style="fill: #2b604b; stroke: #2b604b"
                    >
                      <circle cx="21" cy="21" r="21" style="stroke: none" />
                      <circle cx="21" cy="21" r="20.5" style="fill: none" />
                    </g>
                    <g data-name="Grupo 5660">
                      <path
                        data-name="Trazado 2965"
                        d="m4244.24 7137-9.7 10.182-3.846-3.948"
                        transform="translate(-4216.467 -7121.091)"
                        style="
                          stroke: #fff;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 4px;
                          fill: none;
                        "
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="icon-uncheck" v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26.917"
                  height="27.83"
                  viewBox="0 0 26.917 27.83"
                >
                  <g data-name="Grupo 5705">
                    <path
                      data-name="Trazado 2964"
                      d="m4238 7137 18.435 19.348"
                      transform="translate(-4233.758 -7132.758)"
                      style="
                        fill: none;
                        stroke: #a5a4a4;
                        stroke-linecap: round;
                        stroke-width: 6px;
                      "
                    />
                    <path
                      data-name="Trazado 2965"
                      d="M4256.435 7137 4238 7156.348"
                      transform="translate(-4233.758 -7132.758)"
                      style="
                        fill: none;
                        stroke: #a5a4a4;
                        stroke-linecap: round;
                        stroke-width: 6px;
                      "
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div class="row-data-button">
            <v-btn
              to="/registro"
              color="black"
              class="
                black--text
                py-7
                pr-9
                pl-9
                mr-2
                btn-outline-black
                hover-green
              "
              outlined
              rounded
              >Empieza gratis</v-btn
            >
          </div>
        </div>
        <!-- list planes -->
      </div>
    </v-container>
  </div>
</template>
<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Navigation, Pagination]);
export default {
  data() {
    return {
      listCaracteristicas: [
        {
          titulo: "Beneficio 1",
        },
        {
          titulo: "Beneficio 2",
        },
        {
          titulo: "Beneficio 3",
        },
        {
          titulo: "Beneficio 4",
        },
        {
          titulo: "Beneficio 5",
        },
        {
          titulo: "Beneficio 6",
        },
      ],
      listPlanes: [
        {
          titulo: "Basico",
          precio: "Gratis",
          caracteristicas: [
            {
              name: "one",
              status: true,
            },
            {
              name: "dos",
              status: false,
            },
            {
              name: "three",
              status: true,
            },
            {
              name: "four",
              status: true,
            },
            {
              name: "five",
              status: false,
            },
            {
              name: "six",
              status: false,
            },
          ],
        },
        {
          titulo: "Normal",
          precio: "$400/mes",
          caracteristicas: [
            {
              name: "one",
              status: true,
            },
            {
              name: "dos",
              status: false,
            },
            {
              name: "three",
              status: true,
            },
            {
              name: "four",
              status: true,
            },
            {
              name: "five",
              status: false,
            },
            {
              name: "six",
              status: false,
            },
          ],
        },
        {
          titulo: "Premium",
          precio: "$600/mes",
          caracteristicas: [
            {
              name: "one",
              status: true,
            },
            {
              name: "dos",
              status: false,
            },
            {
              name: "three",
              status: true,
            },
            {
              name: "four",
              status: true,
            },
            {
              name: "five",
              status: false,
            },
            {
              name: "six",
              status: false,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    const sliderPlanes = new Swiper(".swiper-planes", {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 16,
      pagination: {
        el: ".paginationPlanes",
        dynamicBullets: true,
        clickable: true,
      },
    });
  },
};
</script>
<style lang="scss">
.bloque-planes {
  padding-top: 50px;
  padding-bottom: 240px;
  background-color: rgba($color: #ffbf94, $alpha: 0.15);
  width: 100%;

  .column-plan {
    display: flex;
    flex-direction: column;
    transition: all 400ms;
    border-radius: 5px;
    border: solid 1px #000;
    &:hover {
      background-color: #ffffff;
      border: solid 1px #000;
    }
    /* Medium and up */
    @media screen and (min-width: 40em) {
      flex: 1;
      border: solid 1px transparent;
    }
  }
  .row-data,
  .row-title-plan {
    padding: 16px 25px;
    border-bottom: solid 1px rgba($color: #707070, $alpha: 0.15);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* Medium and up */
    @media screen and (min-width: 40em) {
      height: 80px;
    }
  }
  .row-data-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 120px;
  }
  .row-title-plan {
    height: auto;
    min-height: 120px;
    border-bottom: none;
    border-bottom: solid 1px rgba(112, 112, 112, 0.15);
    /* Medium and up */
    @media screen and (min-width: 40em) {
      border-bottom: none;
    }
  }
  .icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .icon-check {
      max-width: 30px;
    }
    .icon-uncheck {
      max-width: 14px;
    }
    .icon-check svg,
    .icon-uncheck svg {
      width: 100%;
    }
  }
  .flex-cards-planes {
    display: none;
    flex-wrap: nowrap;
    .column-caracteristicas {
      flex-basis: 20%;
      flex-shrink: 0;
    }
    /* Medium and up */
    @media screen and (min-width: 40em) {
      display: flex;
    }
  }
  /* Medium and up */
  @media screen and (min-width: 40em) {
    padding-top: 120px;
    padding-bottom: 250px;
    .sliderCardsPlanes {
      display: none;
    }
  }
}
</style>s