import { firebase, auth, db, storage, marcaTiempo, functions } from "@/firebase";
export default {
    namespaced: true,
    state: {
        listUsuarios: [],
    },
    mutations: {
        setUsuarios(state, payload) {
            state.listUsuarios = payload
        }
    },
    actions: {
        async obtieneUsuarios({ commit }) {
            try {
                const queryUsuarios = await db.collection('usuarios').where('role', '==', 'customer').get()
                const list = []
                queryUsuarios.forEach(async userElement => {
                    let objUsuario = {}
                    objUsuario = userElement.data()
                    objUsuario.id = userElement.id
                    let listRestaurantes = []
                    let queryRestaurantes = await db.collection('restaurantes')
                    .where('uid', '==', objUsuario.id)
                    .where('estatus', '==', true).get()
                    queryRestaurantes.forEach(restElement => {
                        let restaurante = {}
                        restaurante = restElement.data()
                        restaurante.id = restElement.id
                        listRestaurantes.push(restaurante)
                    })
                    objUsuario.restaurantes = listRestaurantes
                    list.push(objUsuario)
                })
                commit('setUsuarios', list)
            } catch (error) {
                console.log(error)
            }
        },
        async actualizarEstatus({ commit }, paramUser) {
            try {
                var updateUsuario = firebase.functions().httpsCallable("updateUserStatus");
                var data = { uid: paramUser.uid, estatus: !paramUser.estatus };
                const result = await updateUsuario(data)
                await db.collection('usuarios').doc(paramUser.uid).update({
                    estatus: paramUser.estatus
                })
                return {
                    res: true
                }
            } catch (error) {
                return {
                    res: false,
                    error: error
                }
            }
        },
        checkOutPrediseñada({ commit }) {
            var checkRest = firebase.functions().httpsCallable("createStripeCheckout")
            const stripe = Stripe('pk_test_azBHtHdL6mG4jT4ll5r9Q6Py00w8vbdexD')
            checkRest()
                .then(function (response) {
                    console.log(response)
                    const sesionId = response.data.id
                    stripe.redirectToCheckout({ sessionId: sesionId })
                })
                .catch(function (error) {
                    console.log(error)
                });
        },
    }
}