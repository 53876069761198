import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from "@/firebase";
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/registro',
    name: 'Registro',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Registro.vue')
  },
  {
    path: '/recuperar-contrasenia',
    name: 'RecuperarContrasenia',
    component: () => import(/* webpackChunkName: "login" */ '../views/RecuperarPassword.vue')
  },
  {
    path: '/restaurantes',
    name: 'Restaurantes',
    // route level code-splitting
    // this generates a separate chunk (categorias.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "categorias" */ '../views/restaurante/Restaurantes.vue'),
    meta: {
      requiresAuth: true,
      vendedorAuth: true
    }
  },
  {
    path: '/detalle-restaurant/:id',
    name: 'Detalle-Restaurante',
    component: () => import(/* webpackChunkName: "detalle-restaurant" */ '../views/restaurante/Detalle-Restaurante.vue'),
    meta: {
      requiresAuth: true,
      vendedorAuth: true
    }
  },
  {
    path: '/crear-restaurante',
    name: 'CrearRestaurante',
    // route level code-splitting
    // this generates a separate chunk (crear-restaurant.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "crear-restaurant" */ '../views/restaurante/CrearRestaurante.vue'),
    meta: {
      requiresAuth: true,
      vendedorAuth: true
    }
  },
  {
    path: '/informacion-restaurante/:id?',
    name: 'InformacionRestaurante',
    // route level code-splitting
    // this generates a separate chunk (informacion-restaurante.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "informacion-restaurante" */ '../views/restaurante/Informacion-Restaurante.vue'),
    meta: {
      requiresAuth: true,
      vendedorAuth: true
    },
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    // route level code-splitting
    // this generates a separate chunk (platillos.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "platillos" */ '../views/admin/Usuarios.vue'),
    meta: {
      requiresAuth: true,
      adminAuth: true
    }
  },
  {
    path: '/membresias',
    name: 'Membresias',
    // route level code-splitting
    // this generates a separate chunk (membresias.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "platillos" */ '../views/admin/Membresias.vue'),
    meta: {
      requiresAuth: true,
      adminAuth: true
    }
  },
  {
    path: '/suscripciones',
    name: 'Suscripciones',
    // route level code-splitting
    // this generates a separate chunk (suscripciones.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "platillos" */ '../views/admin/Suscripciones.vue'),
    meta: {
      requiresAuth: true,
      adminAuth: true
    }
  },
  {
    path: '/menu/:id',
    name: 'Menu',
    // route level code-splitting
    // this generates a separate chunk (platillos.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "platillos" */ '../views/menu/Menu.vue'),
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/pagos/',
    name: 'Pagos',
    // route level code-splitting
    // this generates a separate chunk (pagos.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pagos" */ '../views/Pagos.vue')
  },
  {
    path: '/encuestas/',
    name: 'Encuestas',
    // route level code-splitting
    // this generates a separate chunk (encuestas.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "encuestas" */ '../views/encuesta/Encuestas.vue'),
    meta: {
      requiresAuth: true,
      vendedorAuth: true
    }
  },
  {
    path: '/perfil/',
    name: 'Perfil',
    // route level code-splitting
    // this generates a separate chunk (perfil.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "perfil" */ '../views/Perfil.vue'),
    meta: {
      requiresAuth: true,
      vendedorAuth: true
    }
  },
  {
    path: '/crear-encuesta/',
    name: 'CrearEncuesta',
    // route level code-splitting
    // this generates a separate chunk (crear-encuesta.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "crear-encuesta" */ '../views/encuesta/CrearEncuesta.vue'),
    meta: {
      requiresAuth: true,
      vendedorAuth: true
    }
  },
  {
    path: '/informacion-encuesta/:id',
    name: 'InformacionEncuesta',
    // route level code-splitting
    // this generates a separate chunk (informacion-encuesta.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "informacion-encuesta" */ '../views/encuesta/EditarEncuesta.vue'),
    meta: {
      requiresAuth: true,
      vendedorAuth: true
    },
  },
  {
    path: '/responder-encuesta/:id',
    name: 'ResponderEncuesta',
    // route level code-splitting
    // this generates a separate chunk (responder-encuesta.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "responder-encuesta" */ '../views/encuesta/ResponderEncuesta.vue'),
    props: true
  },
  {
    path: '/renovar/',
    name: 'Renovar',
    // route level code-splitting
    // this generates a separate chunk (renovar.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "crear-encuesta" */ '../views/renovar/Renovar.vue'),
    meta: {
      requiresAuth: true,
      vendedorAuth: true
    }
  },
  {
    path: '/faqs',
    name: 'FAQS',
    component: () => import(/* webpackChunkName: "FAQS" */ '../views/Faqs.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

const originalPush = router.push
router.push = function push(location, onResolve, onReject)
{
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
 
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }
   
        return Promise.reject(err)
    })
}

router.beforeEach((to, from, next) => {
  const user = auth.currentUser;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (user) {
      auth.currentUser.getIdTokenResult()
        .then(function ({
          claims
        }) {
          if (to.meta.adminAuth) {
            if (claims.admin) {
              next();
            } else {
              next('/')
            }
          }
          else if (to.meta.vendedorAuth) {
            if (claims.customer) {
              next();
            } else {
              next('/')
            }
          }
          else{
            next()
          }
        })
    } else {
      next('/login')
    }
  } else {
    next() // make sure to always call next()!
  }
})