import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from "@/firebase";
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
 
Vue.use(VueMoment, {
    moment,
})

Vue.config.productionTip = false


auth.onAuthStateChanged(function (user) {
  if (user) {
    auth.currentUser.getIdTokenResult()
      .then(tokenResult => {
        let claims = tokenResult.claims
        user.claims = claims 
        //valido que haya claims
        if(claims.admin || claims.customer){
          store.dispatch('setUsuario', user);
          if(claims.customer){
            store.dispatch('adminSuscripciones/obtieneMembresiaCurrent', user, { root: true });
          }
        }
      });
  }
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});