import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import { firebase, auth, db, storage, marcaTiempo } from "@/firebase";
import adminUsers from '../modules/adminUsers';
import adminMembresias from '../modules/adminMembresias';
import adminSuscripciones from '../modules/adminSuscripciones';
import moduloRestaurantes from '../modules/moduloRestaurantes';
import moduloEncuestas from '../modules/moduloEncuestas';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario: null,
    error: "",
    listRestaurantes: [],
    listRestaurantesMenu: [],
    restaurantCurrent: {},
    platillos: [],
    categorias: [],
    subcategorias: [],
    menu: [],
    logoNav: '',
    colorNav: '',
    estatusRegistro: '',
    urlImgPlatilloModal: '',
    encuestas: [],
    facebook: '',
    instagram: ''
  },
  mutations: {
    setUsuario(state, payload) {
      state.usuario = payload
    },
    setError(state, payload) {
      let mensajeFound = ""
      switch (payload) {
        case 'auth/email-already-in-use':
          mensajeFound = "El correo ya se encuentra en uso en otra cuenta"
          break;
        case 'auth/user-not-found':
          mensajeFound = "La contraseña o el email son incorrectos"
          break;
        case 'auth/wrong-password':
          mensajeFound = "La contraseña o el email son incorrectos"
          break;
        case 'auth/weak-password':
          mensajeFound = "La contraseña debe tener al menos 6 caracteres"
          break;
        case 'auth/invalid-email':
          mensajeFound = "El correo es incorrecto"
          break;
        case 'auth/user-disabled':
          mensajeFound = "El acceso ha sido deshabilitado, contacta al administrador"
          break;
        default:
          mensajeFound = "Se genero un error, vuelve a intentarlo más tarde"
          break;
      }
      state.error = mensajeFound
    },
    setAgregarRestaurante(state, payload) {
      state.listRestaurantes.push(payload)
    },
    setRestaurantes(state, payload) {
      state.listRestaurantes = payload
    },
    setRestaurantesMenu(state, payload) {
      state.listRestaurantesMenu = payload
    },
    setFacebook(state, payload){
      state.facebook = payload;
    },
    setInstagram(state, payload){
      state.instagram = payload;
    },
    deleteRestaurantes(state, payload) {
      state.listRestaurantes = state.listRestaurantes.filter(item => item.id !== payload.id)
    },
    setRestaurantCurrent(state, payload) {
      state.restaurantCurrent = payload
    },
    addPlatillo(state, payload) {
      state.platillos.push(payload)
    },
    setPlatillos(state, payload) {
      state.platillos = payload
    },
    updatePlatillos(state, payload) {
      state.platillos = state.platillos.map(item => item.id === payload.id ? payload : item)
    },
    deletePlatillo(state, payload) {
      state.platillos = state.platillos.filter(item => item.id !== payload.id)
    },
    addCategoria(state, payload) {
      state.categorias.push(payload)
    },
    setCategorias(state, payload) {
      state.categorias = payload
    },
    deleteCategorias(state, payload) {
      state.categorias = state.categorias.filter(item => item.id !== payload)
    },
    addSubCategoria(state, payload) {
      state.subcategorias.push(payload)
    },
    setSubCategorias(state, payload) {
      state.subcategorias = payload
    },
    updateSubCategorias(state, payload) {
      state.subcategorias = state.subcategorias.map(item => item.id === payload.id ? payload : item)
    },
    deleteSubCategorias(state, payload) {
      state.subcategorias = state.subcategorias.filter(item => item.id !== payload)
    },
    setMenu(state, payload) {
      state.menu = payload
    },
    setLogo(state, payload) {
      state.logoNav = payload
    },
    setColorNav(state, payload) {
      state.colorNav = payload
    },
    setRegistroEstatus(state, payload) {
      state.estatusRegistro = payload
    },
    setImgModalPlatillo(state, payload) {
      state.urlImgPlatilloModal = payload
    },
    setEncuestas(state, payload) {
      state.encuestas = payload
    },
    deleteEncuestas(state, payload) {
      state.encuestas = state.encuestas.filter(item => item.id !== payload)
    },
  },
  actions: {
    setUsuario({ commit }, user) {
      const usuario = {
        email: user.email,
        uid: user.uid,
        foto: user.photoURL,
        claims: user.claims
      }
      commit('setUsuario', usuario)
    },
    async cerrarSesion({ commit }) {
      await auth.signOut();
      commit('setUsuario', null)
      router.push('/login')
      // router.go();
    },
    async registroUsuarioCorreo({ commit, state }, usuario) {
      try {
        const result = await firebase.auth().createUserWithEmailAndPassword(usuario.email, usuario.password)
        const user = result.user
        auth.signOut()
        commit('setRegistroEstatus', 'Gracias por registrarte, ya puedes iniciar sesión')
        return {
          res: true
        }
      } catch (error) {
        commit('setError', error.code)
        return {
          res: false,
          error: state.error
        }
      }
    },
    async loginUsuarioCorreo({ commit, state }, usuarioParam) {
      try {
        const result = await firebase.auth().signInWithEmailAndPassword(usuarioParam.email, usuarioParam.password)
        const user = result.user
        const objUser = {
          nombre: user.displayName,
          email: user.email,
          uid: user.uid,
          foto: user.photoURL,
        };
        auth.onAuthStateChanged(function (user) {
          if (user) {
            auth.currentUser.getIdTokenResult(true)
              .then(tokenResult => {
                let claims = tokenResult.claims
                objUser.claims = claims
                commit('setUsuario', objUser)
                if (claims.admin) {
                  router.push("/usuarios");
                } else if (claims.customer) {
                  router.push("/restaurantes");
                }
              });
          }
        });
        commit('setRegistroEstatus', '')
        return {
          res: true
        }
      } catch (error) {
        commit('setError', error.code)
        return {
          res: false,
          error: state.error
        }
      }
    },
    async recuperarContra({commit}, correoParam){
      try {
        firebase.auth().languageCode = 'es';
        await firebase.auth().sendPasswordResetEmail(correoParam.correo);
        return{
          res: true,
          error: ""
        }
      } catch (error) {
        console.log(error);
        return{
          res: false,
          error: "Hubo un error al enviar, verifica tus datos"
        }
      }
    },
    async obtieneRestaurantesMenu({ commit, state }) {
      try {
        if (state.usuario == null)
          return
        const query = await db.collection('restaurantes')
          .where('uid', "==", state.usuario.uid)
          .where('estatus', '==', true)
          .get()
        const list = []
        query.forEach(async element => {
          let restaurantObject = element.data();
          restaurantObject.id = element.id;
          const letExitCategory = await db.collection('restaurantes')
            .doc(restaurantObject.id).collection('categorias').get()
          if (!letExitCategory.size > 0) {
            list.push(restaurantObject)
          }
        });
        commit('setRestaurantesMenu', list)
      } catch (error) {
        console.log(error)
      }
    },
    async obtieneDetalleRestaurante({ commit }, id) {
      try {
        const query = await db.collection('restaurantes').doc(id).get()
        let dataRestaurant = query.data()
        dataRestaurant.id = query.id;
        commit('setRestaurantCurrent', dataRestaurant)
      } catch (error) {
        console.log(error)
      }
    },
    async creaRestaurante({ commit, state }, restauranteParam) {
      try {
        //valido que no exista un slug
        const existSlug = await db.collection('restaurantes').where('slug', '==', restauranteParam.slug).get()
        if (!existSlug.size > 0) {
          let restaurantInsert = {
            slug: restauranteParam.slug,
            foto: '',
            fondoImg: '',
            imgPrincipal: '',
            colorTextoPrincipal: restauranteParam.colorTextoPrincipal,
            colorBar: restauranteParam.colorBar,
            tipoFondo: restauranteParam.tipoFondo,
            colorFondo: restauranteParam.colorFondo,
            nombre: restauranteParam.nombre,
            uid: state.usuario.uid,
            fecha: marcaTiempo(),
            estatus: true
          }
          const createRestaurante = await db.collection('restaurantes').add(restaurantInsert)
          restaurantInsert.id = createRestaurante.id
          //valido que haya imagen para almacenarla
          if (restauranteParam.foto && restauranteParam.foto.size > 0) {
            const refImagen = storage.ref().child(state.usuario.email)
              .child('logosRestaurante').child(restaurantInsert.id)
              .child('logo')

            const res = await refImagen.put(restauranteParam.foto)
            const urlDescarga = await refImagen.getDownloadURL()
            restaurantInsert.foto = urlDescarga
            //actualiza foto del restaurante
            await db.collection('restaurantes').doc(restaurantInsert.id).update({
              foto: urlDescarga
            })
          }
          // valido quey hay una imagen principal
          if (restauranteParam.imgPrincipal && restauranteParam.imgPrincipal.size > 0) {
            const refImagen = storage.ref().child(state.usuario.email)
              .child('imgBanners').child(restaurantInsert.id)
              .child('banner')

            const res = await refImagen.put(restauranteParam.imgPrincipal)
            const urlDescarga = await refImagen.getDownloadURL()
            restaurantInsert.fondoImg = urlDescarga
            //actualiza foto del restaurante
            await db.collection('restaurantes').doc(restaurantInsert.id).update({
              imgPrincipal: urlDescarga
            })
          }
          // valido que haya una imagen de fondo
          if (restauranteParam.fotoFondo && restauranteParam.fotoFondo.size > 0) {
            const refImagen = storage.ref().child(state.usuario.email)
              .child('fondosRestaurante').child(restaurantInsert.id)
              .child('fondo')

            const res = await refImagen.put(restauranteParam.fotoFondo)
            const urlDescarga = await refImagen.getDownloadURL()
            restaurantInsert.fondoImg = urlDescarga
            //actualiza foto del restaurante
            await db.collection('restaurantes').doc(restaurantInsert.id).update({
              fondoImg: urlDescarga
            })
          }
          commit('setAgregarRestaurante', restaurantInsert)
          return {
            res: true,
            error: ''
          }
        } else {
          return {
            res: false,
            error: 'El slug ya se encuentra en uso'
          }
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async actualizaRestaurante({ commit, state }, paramRestaurante) {
      try {
        let restaurantUpdate = {
          colorTextoPrincipal: paramRestaurante.colorTextoPrincipal,
          colorBar: paramRestaurante.colorBar,
          tipoFondo: paramRestaurante.tipoFondo,
          colorFondo: paramRestaurante.colorFondo,
          foto: paramRestaurante.foto,
          imgPrincipal: paramRestaurante.imgPrincipal,
          fondoImg: paramRestaurante.fondoImg
        }
        let objReturn = {
          res: true,
          error: ''
        }
        //valido que haya imagen para almacenarla
        if (paramRestaurante.imagenLogo && paramRestaurante.imagenLogo.size > 0) {
          const refImagen = storage.ref().child(state.usuario.email)
            .child('logosRestaurante').child(paramRestaurante.id)
            .child('logo')
          const res = await refImagen.put(paramRestaurante.imagenLogo)
          //se obtiene la url donde se almaceno en firestore
          const urlDescarga = await refImagen.getDownloadURL()
          restaurantUpdate.foto = urlDescarga
          objReturn.foto = urlDescarga
        }
        // valido quey hay una imagen principal
        if (paramRestaurante.imagenPricipal && paramRestaurante.imagenPricipal.size > 0) {
          const refImagen = storage.ref().child(state.usuario.email)
            .child('imgBanners').child(paramRestaurante.id)
            .child('banner')
          const res = await refImagen.put(paramRestaurante.imagenPricipal)
          const urlDescarga = await refImagen.getDownloadURL()
          restaurantUpdate.imgPrincipal = urlDescarga
          objReturn.imgPrincipal = urlDescarga
        }
        //valido que haya imagen para almacenarla
        if (paramRestaurante.imagenFondo && paramRestaurante.imagenFondo.size > 0) {
          const refImagen = storage.ref().child(state.usuario.email)
            .child('fondosRestaurante').child(paramRestaurante.id)
            .child('fondo')
          const res = await refImagen.put(paramRestaurante.imagenFondo)
          //se obtiene la url donde se almaceno en firestore
          const urlDescarga = await refImagen.getDownloadURL()
          restaurantUpdate.fondoImg = urlDescarga
          objReturn.fondoImg = urlDescarga
        }
        await db.collection('restaurantes').doc(paramRestaurante.id).update(restaurantUpdate)
        return objReturn;
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async obtieneCategorias({ commit }, idParam) {
      try {
        const query = await db.collection('restaurantes')
          .doc(idParam).collection('categorias').orderBy("order", "asc").get()
        const list = []
        query.forEach(element => {
          let categoria = element.data()
          categoria.id = element.id
          categoria.dialog = false,
          categoria.comentarios = (categoria.comentarios) ? categoria.comentarios : "";
            list.push(categoria)
        });
        commit('setCategorias', list)
        return {
          res: true,
        }
      } catch (error) {
        return {
          res: false,
          error: 'Se género un error al cancelar, te recomendamos recargar la pagina.'
        }
      }
    },
    async creaCategoria({ commit, state }, paramCategoria) {
      try {
        let categoria = {
          urlImage: '',
          title: paramCategoria.title,
          color: paramCategoria.color,
          colorCaja: paramCategoria.colorCaja,
          estatus: true,
          order: paramCategoria.order,
          comentarios: paramCategoria.comentarios
        }
        const createCategory = await db.collection('restaurantes')
          .doc(paramCategoria.idRestaurante)
          .collection("categorias").add(categoria)
        categoria.id = createCategory.id
        //para modales
        categoria.dialog = false
        //valido que haya imagen para almacenarla
        if (paramCategoria.imagen && paramCategoria.imagen.size > 0) {
          const refImagen = storage.ref().child(state.usuario.email)
            .child('categorias').child(categoria.id)

          const res = await refImagen.put(paramCategoria.imagen)
          const urlDescarga = await refImagen.getDownloadURL()
          categoria.urlImage = urlDescarga
          //actualiza foto del restaurante
          await db.collection('restaurantes')
            .doc(paramCategoria.idRestaurante)
            .collection("categorias").doc(categoria.id).update({
              urlImage: urlDescarga
            })
        }
        commit('addCategoria', categoria)
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async actualizaCategoria({ commit, state }, paramCategoria) {
      try {
        let categoria = {
          title: paramCategoria.title,
          color: paramCategoria.color,
          colorCaja: paramCategoria.colorCaja,
          urlImage: paramCategoria.urlImage,
          comentarios: paramCategoria.comentarios
        }
        let objReturn = {
          res: true,
          error: ''
        }
        //valido que haya imagen para almacenarla
        if (paramCategoria.imagen && paramCategoria.imagen.size > 0) {
          const refImagen = storage.ref().child(state.usuario.email)
            .child('categorias').child(paramCategoria.id)
          const res = await refImagen.put(paramCategoria.imagen)
          const urlDescarga = await refImagen.getDownloadURL()
          categoria.urlImage = urlDescarga
          objReturn.urlImage = urlDescarga
        }
        await db.collection('restaurantes')
          .doc(state.restaurantCurrent.id)
          .collection('categorias').doc(paramCategoria.id).update(categoria)
        return objReturn
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async desactivarCategoria({ commit }, paramCategoria) {
      try {
        const refRestaurantes = db.collection('restaurantes')
          .doc(paramCategoria.idRestaurante)
        await refRestaurantes.collection('categorias')
          .doc(paramCategoria.idCategoria).update({
            estatus: paramCategoria.estatus
          })
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async eliminaCategoria({ commit, state }, paramCategoria) {
      try {
        const refRestaurantes = db.collection('restaurantes')
          .doc(paramCategoria.idRestaurante)
        const platillos = await refRestaurantes.collection('platillos')
          .where('idCategoria', '==', paramCategoria.idCategoria)
        //elimino la imagen que se subio de la categoria
        if (paramCategoria.urlImage) {
          const refImagen = storage.ref().child(state.usuario.email)
            .child('categorias').child(paramCategoria.idCategoria)
          refImagen.delete()
        }
        //valido si la categoria tiene platillos para remover referencias en los platillos
        if (platillos.size > 0) {
          platillos.forEach(doc => {
            doc.ref
              .update({
                idCategoria: 0,
                idSubcategoria: 0
              })
          })
        }
        await refRestaurantes.collection('categorias')
          .doc(paramCategoria.idCategoria).delete()
        //actualizo datos para platillos
        commit('deleteCategorias', paramCategoria.idCategoria)
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async obtieneSubCategoriasRestaurante({ commit }, idRestaurante) {
      try {
        const query = await db.collection('restaurantes')
          .doc(idRestaurante)
          .collection('subcategorias')
          .get()
        const list = []
        query.forEach(element => {
          let subcategoria = element.data()
          subcategoria.id = element.id
          subcategoria.order = (element.order) ? element.order : 0;
          list.push(subcategoria)
        });
        commit('setSubCategorias', list)
        return {
          res: true
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async creaSubCategoria({ commit, state }, paramCategoria) {
      try {
        let subcategoria = {
          title: paramCategoria.title,
          idCategoria: paramCategoria.idCategoria,
          estatus: true,
          order: paramCategoria.order
        }
        const createSubCategory = await db.collection('restaurantes')
          .doc(state.restaurantCurrent.id)
          .collection("subcategorias").add(subcategoria)
        subcategoria.id = createSubCategory.id
        commit('addSubCategoria', subcategoria)
        return {
          res: true,
          data: subcategoria,
          error: ''
        }
      } catch (error) {
        console.log(error)
        return {
          res: false,
          error: error
        }
      }
    },
    async actualizaSubCategoria({ commit }, paramSubCategoria) {
      try {
        let subcategoria = {
          title: paramSubCategoria.title,
          order: paramSubCategoria.order
        }
        await db.collection('restaurantes')
          .doc(paramSubCategoria.idRestaurante)
          .collection('subcategorias').doc(paramSubCategoria.id).update(subcategoria)
        //le paso un objeto para que se reemplace, simulando una actualizacion al state
        commit('updateSubCategorias', paramSubCategoria);
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        console.log(error)
        return {
          res: false,
          error: error
        }
      }
    },
    async desactivarSubCategoria({ commit }, paramSubCategoria) {
      try {
        const refRestaurantes = db.collection('restaurantes')
          .doc(paramSubCategoria.idRestaurante)
        await refRestaurantes.collection('subcategorias')
          .doc(paramSubCategoria.idSub).update({
            estatus: paramSubCategoria.estatus
          })
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async eliminaSubcategoria({ commit }, paramSubCategoria) {
      try {
        const refRestaurantes = db.collection('restaurantes')
          .doc(paramSubCategoria.idRestaurante)
        const platillos = await refRestaurantes.collection('platillos')
          .where('idSubcategoria', '==', paramSubCategoria.idSub)
        //valido que la subcategoria tenga platillos en caso de que si
        if (platillos.size > 0) {
          //reseteo los platillos que tenga la subcategoria
          platillos.forEach(doc => {
            doc.ref
              .update({
                idSubcategoria: 0
              })
          })
        }
        //si no tiene platillos elimino la subcategoria para no generar basura en la bd
        await refRestaurantes.collection('subcategorias')
          .doc(paramSubCategoria.idSub).delete()
        commit('deleteSubCategorias', paramSubCategoria.idSub)
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async obtienePlatillos({ commit }, idRestaurante) {
      try {
        const query = await db.collection('restaurantes')
          .doc(idRestaurante)
          .collection('platillos')
          .get()
        const list = []
        query.forEach(element => {
          let platillo = element.data()
          platillo.precioEspecial = (platillo.precioEspecial) ? platillo.precioEspecial : ""
          platillo.id = element.id
          list.push(platillo)
        });
        commit('setPlatillos', list)
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async creaPlatillo({ commit, state }, paramPlatillo) {
      try {

        let platillo = {
          urlImage: '',
          title: paramPlatillo.title,
          descripcion: paramPlatillo.descripcion,
          precio: paramPlatillo.precio,
          precioUnitario: paramPlatillo.precioUnitario,
          labelUnitario: paramPlatillo.labelUnitario,
          estatus: true,
          idCategoria: paramPlatillo.idCategoria,
          idSubcategoria: paramPlatillo.idSubcategoria,
          precioEspecial: paramPlatillo.precioEspecial
        }
        const result = await db.collection('restaurantes')
          .doc(paramPlatillo.idRestaurante)
          .collection('platillos')
          .add(platillo)
        //agrego al id que se genera automaticamente
        platillo.id = result.id

        //valido que haya imagen para almacenarla
        if (paramPlatillo.imagen && paramPlatillo.imagen.size > 0) {
          const refImagen = storage.ref().child(state.usuario.email)
            .child('platillos').child(platillo.id)

          const res = await refImagen.put(paramPlatillo.imagen)
          const urlDescarga = await refImagen.getDownloadURL()
          platillo.urlImage = urlDescarga
          //actualiza foto del restaurante
          await db.collection('restaurantes')
            .doc(paramPlatillo.idRestaurante)
            .collection('platillos').doc(platillo.id).update({
              urlImage: urlDescarga
            })
        }
        commit('addPlatillo', platillo)
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async actualizaPlatillo({ commit, state }, paramPlatillo) {
      try {
        let platillo = {
          title: paramPlatillo.title,
          descripcion: paramPlatillo.descripcion,
          precio: paramPlatillo.precio,
          precioUnitario: (paramPlatillo.precioUnitario == "") ? 0 : paramPlatillo.precioUnitario,
          labelUnitario: paramPlatillo.labelUnitario,
          idCategoria: paramPlatillo.idCategoria,
          idSubcategoria: paramPlatillo.idSubcategoria,
          urlImage: paramPlatillo.urlImage,
          precioEspecial: paramPlatillo.precioEspecial
        }
        //valido que haya imagen para remplazarla
        if (paramPlatillo.imagen && paramPlatillo.imagen.size > 0) {
          const refImagen = storage.ref().child(state.usuario.email)
            .child('platillos').child(paramPlatillo.id)

          const res = await refImagen.put(paramPlatillo.imagen)
          const urlDescarga = await refImagen.getDownloadURL()
          platillo.urlImage = urlDescarga
        }
        await db.collection('restaurantes')
          .doc(paramPlatillo.idRestaurante)
          .collection('platillos')
          .doc(paramPlatillo.id)
          .update(platillo)
        commit('updatePlatillos', paramPlatillo)
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async eliminaPlatillo({ commit, state }, paramPlatillo) {
      try {
        //valido que haya imagen para eliminarla
        if (paramPlatillo.urlImage) {
          const refImagen = storage.ref().child(state.usuario.email)
            .child('platillos').child(paramPlatillo.id)

          refImagen.delete()
        }
        await db.collection('restaurantes')
          .doc(paramPlatillo.idRestaurante)
          .collection('platillos').doc(paramPlatillo.id).delete()
        commit('deletePlatillo', paramPlatillo)
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async desactivaPlatillo({ commit }, paramPlatillo) {
      try {
        await db.collection('restaurantes')
          .doc(paramPlatillo.idRestaurante)
          .collection('platillos').doc(paramPlatillo.id).update({
            estatus: paramPlatillo.estatus
          })
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async obtieneMenu({ commit }, idRestaurante) {
      try {
        //consulto el restaurante para el obtener el logo - imagen
        const query = await db.collection('restaurantes')
          .where('slug', '==', idRestaurante)
          .where('estatus', '==', true)
          .limit(1).get()
        let dataRestaurant = {}
        query.forEach(async element => {
          dataRestaurant = element.data()
          dataRestaurant.id = element.id
        })
        commit('setFacebook', dataRestaurant.facebook)
        commit('setInstagram', dataRestaurant.instagram)
        commit('setLogo', dataRestaurant.foto)
        commit('setColorNav', dataRestaurant.colorBar)
        // obtengo las categorias, es una subcollecion del restaurante
        const queryCategorias = await db.collection("restaurantes")
          .doc(dataRestaurant.id)
          .collection('categorias')
          .where('estatus', '==', true)
          .orderBy("order", "asc").get()

        let listCategorias = []
        queryCategorias.forEach(element => {
          let categoria = element.data()
          categoria.id = element.id
          listCategorias.push(categoria)
        })
        // obtengo las subcategorias, es una subcollecion del restaurante
        const subcategorias = await db.collection("restaurantes")
          .doc(dataRestaurant.id)
          .collection('subcategorias')
          .where('estatus', '==', true)
          .orderBy('order', "asc").get()
        let listSub = []
        subcategorias.forEach(element => {
          let subcat = element.data()
          subcat.id = element.id
          listSub.push(subcat)
        })
        // obtengo los platillos es una subcollecion del restaurante
        const platillos = await db.collection("restaurantes")
          .doc(dataRestaurant.id)
          .collection('platillos')
          .where('estatus', '==', true).get()
        let listPlatillos = []
        platillos.forEach(element => {
          let platillo = element.data()
          platillo.id = element.id
          listPlatillos.push(platillo)
        })
        //una ves obtenidos los datos hago el menu
        let menuData = {
          imgFondo: dataRestaurant.fondoImg,
          colorTextoPrincipal: dataRestaurant.colorTextoPrincipal,
          listMenu: [],
          tipoFondo: dataRestaurant.tipoFondo,
          colorFondo: dataRestaurant.colorFondo,
          imgPrincipal: dataRestaurant.imgPrincipal
        }
        if (listCategorias.length > 0) {
          //filtro las que tenga estatus true para no generar un indice compuesto en la bd
          // listCategorias = listCategorias.filter(item => item.estatus == true)
          //recorro las categorias
          listCategorias.forEach(catElement => {
            if(catElement.comentarios){
              let comentariosHtml = [];
              let comentarioSplit = catElement.comentarios.split("\n").forEach(item=> comentariosHtml.push(`${item.trim()}`));
              let htmlUpComentario = comentariosHtml.join('<br>');
              catElement.comentarios = htmlUpComentario;
            }else{
              catElement.comentarios = "";
            }
            let categoria = catElement
            categoria.platillos = []
            //valido que hay platillos
            if (listPlatillos.length > 0) {
              categoria.platillos = listPlatillos.filter(item => item.idCategoria == categoria.id && item.idSubcategoria == 0)
              //recorro las subcategorias
              if (listSub.length > 0) {
                //si tiene le creo los subniveles
                let listSubFound = []
                listSub.forEach(element => {
                  let subcategoria = element
                  //valido que la subcategoria le pertenesca a la categoria - padre
                  if (subcategoria.idCategoria == categoria.id) {
                    subcategoria.platillos = listPlatillos.filter(item => item.idSubcategoria == subcategoria.id)
                    //valido que tenga platillos
                    if (subcategoria.platillos.length > 0)
                      listSubFound.push(subcategoria)
                  }
                });
                categoria.subcategorias = listSubFound
              }
            }
            menuData.listMenu.push(categoria)
          })
        }
        // seteo el menu al store para que se visualize
        commit('setMenu', menuData)
      } catch (error) {
        console.log(error)
      }
    },
    async obtieneAparienciaMenu({commit}, slug){
      try {
        //consulto el restaurante para el obtener el logo - imagen
        const query = await db.collection('restaurantes')
          .where('slug', '==', slug)
          .where('estatus', '==', true)
          .limit(1).get()
        let dataRestaurant = {}
        query.forEach(async element => {
          dataRestaurant = element.data()
          dataRestaurant.id = element.id
        })
        commit('setLogo', dataRestaurant.foto)
        commit('setColorNav', dataRestaurant.colorBar)
      } catch (error) {
        console.log(error)
      }
    },
    async subirMenu({ commit }, paramMenu) {
      try {
        const batch = db.batch();
        const categoriasMenu = paramMenu.categoriasMenu;
        const idRestaurante = paramMenu.idRestaurante;
        categoriasMenu.forEach(elementCategoria => {
          //creo la referencia de las categorias a insertar
          var referenciaCategoria = db.collection('restaurantes')
            .doc(idRestaurante)
            .collection("categorias").doc()
          //id categoria que se genera en automatico 
          var idCategoria = referenciaCategoria.id
          //genero el batch que agrega la categoria
          batch.set(referenciaCategoria, {
            'urlImage': '',
            'title': elementCategoria.title,
            'color': "",
            'colorCaja': "#FFFFFF00",
            'estatus': true,
            'order': elementCategoria.order
          })
          //recorro los platillos a insertar a la categoria
          var platillosCategoria = elementCategoria.platillos
          if (platillosCategoria.length > 0) {
            platillosCategoria.forEach(elementPlatCat => {
              //creo la referencia del platillo a insertar
              var refPlatilloCategoria = db.collection('restaurantes')
                .doc(idRestaurante)
                .collection("platillos").doc()
              //genero el batch que agrega el platillo a la categoria
              batch.set(refPlatilloCategoria, {
                'urlImage': '',
                'title': elementPlatCat.title,
                'descripcion': elementPlatCat.descripcion,
                'precio': elementPlatCat.precio,
                'precioUnitario': elementPlatCat.precioUnitario,
                'labelUnitario': elementPlatCat.labelUnitario,
                'estatus': true,
                'idCategoria': idCategoria,
                'idSubcategoria': 0
              })
            });
          }
          //recorro las subcategorias a insertar
          var subCategoriasInsert = elementCategoria.subcategorias
          if (subCategoriasInsert.length > 0) {
            subCategoriasInsert.forEach(elementSubCategoria => {
              var refSubCategoria = db.collection('restaurantes')
                .doc(idRestaurante)
                .collection("subcategorias").doc()
              //id subcategoria que se genera en automatico 
              var idSubcategoria = refSubCategoria.id
              //genero el batch que agrega la subcategoria a la categoria
              batch.set(refSubCategoria, {
                'title': elementSubCategoria.title,
                'idCategoria': idCategoria,
                'estatus': true,
                'order': elementSubCategoria.order
              })
              //creo los platillos que tenga la subcategoria
              var platilloSubCategoria = elementSubCategoria.platillos
              if (platilloSubCategoria.length > 0) {
                platilloSubCategoria.forEach(elementPlatSub => {
                  //creo la referencia del platillo a insertar
                  var refPlatilloSub = db.collection('restaurantes')
                    .doc(idRestaurante)
                    .collection("platillos").doc()
                  //genero el batch que agrega el platillo a la subCategoria
                  batch.set(refPlatilloSub, {
                    'urlImage': '',
                    'title': elementPlatSub.title,
                    'descripcion': elementPlatSub.descripcion,
                    'precio': elementPlatSub.precio,
                    'precioUnitario': elementPlatSub.precioUnitario,
                    'labelUnitario': elementPlatSub.labelUnitario,
                    'estatus': true,
                    'idCategoria': idCategoria,
                    'idSubcategoria': idSubcategoria
                  })
                })
              }
            });
          }
        });
        await batch.commit();
        return {
          res: true
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async obtieneEncuestasFilter({ commit }, idRestaurante) {
      try {
        const queryRestaurantes = await db.collection('restaurantes')
        .doc(idRestaurante).collection("encuestas").get();
        const list = [];
        queryRestaurantes.forEach(element => {
          let encuestaObject = element.data();
          encuestaObject.id = element.id;
          encuestaObject.dialog = false;
          list.push(encuestaObject)
        });
        commit("setEncuestas", list);
        return {
          res: true
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async obtieneEncuestas({ commit, state }) {
      try {
        if (state.usuario == null)
          return
        const queryRestaurantes = await db.collection('restaurantes')
          .where('uid', "==", state.usuario.uid)
          .where('estatus', '==', true).collection("encuestas").get();
        const list = []
        queryRestaurantes.forEach(element => {
          let encuestaObject = element.data();
          encuestaObject.id = element.id;
          encuestaObject.dialog = false;
          list.push(encuestaObject);
        });
        commit("setEncuestas", list)
        return {
          res: true
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async actualizaEncuesta({ commit }, paramEncuesta) {
      try {
        const objEncuesta = {
            title: paramEncuesta.title,
            descripcion: paramEncuesta.descripcion,
            preguntas: paramEncuesta.preguntas,
            colorTexto: paramEncuesta.colorTexto,
            colorPreguntas: paramEncuesta.colorPreguntas,
        };
        await db.collection('restaurantes')
          .doc(paramEncuesta.idRestaurante).collection("encuestas").doc(paramEncuesta.id).update(objEncuesta);
        return {
          res: true
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async eliminaEncuesta({commit}, paramEncuesta){
      try {
        const refRestaurantes = db.collection('restaurantes')
        .doc(paramEncuesta.idRestaurante)
        await refRestaurantes.collection('encuestas')
          .doc(paramEncuesta.id).delete()
        commit('deleteEncuestas', paramEncuesta.id);
        return {
          res: true
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
    async desactivarEncuesta({ commit }, paramEncuesta) {
      try {
        const refRestaurantes = db.collection('restaurantes')
          .doc(paramEncuesta.idRestaurante)

        await refRestaurantes.collection('encuestas')
          .doc(paramEncuesta.idEncuesta).update({
            estatus: paramEncuesta.estatus
          })
        return {
          res: true,
          error: ''
        }
      } catch (error) {
        return {
          res: false,
          error: error
        }
      }
    },
  },
  getters: {
    usuarioAutenticado(state) {
      return !!state.usuario
    }
  },
  modules: {
    //modulo que tiene la logica de usuarios para el administrador
    adminUsers,
    adminMembresias,
    adminSuscripciones,
    moduloRestaurantes,
    moduloEncuestas
  }
});